import { RuleProcessor, defaultRuleProcessorMongoDB } from 'react-querybuilder'

export const regexOperators = [
  'contains',
  'beginsWith',
  'endsWith',
  'doesNotContain',
  'doesNotBeginWith',
  'doesNotEndWith',
]

export const ruleProcessor: RuleProcessor = (rule, options) => {
  // When using some operators, a regex is used to match the value, if we don't escape special characters, the query will fail
  if (regexOperators.includes(rule.operator)) {
    return defaultRuleProcessorMongoDB({ ...rule, value: escapeRegexCharacters(rule.value) }, options)
  }

  return defaultRuleProcessorMongoDB(rule, options)
}

export const escapeRegexCharacters = (str: string) => {
  const specialChars = ['$', '(', ')', '*', '+', '.', '=', '?', '[', ']', '\\', '^', '{', '}', '|']

  return str
    .split('')
    .map((char) => (specialChars.includes(char) ? '\\' + char : char))
    .join('')
}

export const unescapeRegexCharacters = (str: string): string => {
  const escapedSpecialChars = /\\([$()*+.=?[\\\]^{|}])/g
  return str.replace(escapedSpecialChars, '$1')
}
