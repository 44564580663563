import { useMutation, useQueryClient } from '@tanstack/react-query'
import { getGenericClient } from '../../client'
import { WorkspaceObject } from '../../features/workspaces/types'
import { getQueryKey } from '../../services'
import { showErrorToast } from '@bpinternal/ui-kit'
import { handleHttpErrors } from '../../shared'

type CreateWorkspaceParams = { onSuccess?: (createdWorkspace: WorkspaceObject) => void } & Parameters<
  ReturnType<typeof getGenericClient>['createWorkspace']
>['0']

export function useCreateWorkspace() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ name }: CreateWorkspaceParams) => {
      return await getGenericClient().createWorkspace({ name }).catch(handleHttpErrors)
    },
    onSuccess: async (createdWorkspace, { onSuccess }) => {
      await queryClient.invalidateQueries({ queryKey: getQueryKey('workspaces_') })

      onSuccess?.(createdWorkspace)
    },
    onError: (error: Error) => {
      showErrorToast(error.message)
    },
  })
}
