import { defaultOperators, DefaultOperatorName } from 'react-querybuilder'
import { QBFields } from './types'

type Operator = { name: DefaultOperatorName; label: string }

type OperatorName = 'null' | 'in' | 'is'

const ops: Record<OperatorName, Operator[]> = {
  null: [
    { name: 'null', label: 'is null' },
    { name: 'notNull', label: 'is not null' },
  ],
  in: [
    { name: 'in', label: 'in' },
    { name: 'notIn', label: 'not in' },
  ],
  is: [
    { name: '=', label: 'is' },
    { name: '!=', label: 'is not' },
  ],
}

export const getOperators =
  (fields: QBFields) =>
  (fieldName: string): { name: DefaultOperatorName; label: string }[] => {
    const field = fields[fieldName]

    if (field === undefined) {
      return []
    }

    switch (field.inputType) {
      case 'string':
        return [
          ...(ops.is || []),
          ...defaultOperators.filter((op) =>
            [
              'contains',
              'beginsWith',
              'endsWith',
              'doesNotContain',
              'doesNotBeginWith',
              'doesNotEndWith',
              'null',
              'notNull',
              'in',
              'notIn',
            ].includes(op.name)
          ),
        ]

      case 'number':
        return [
          { name: '=', label: 'is equal to' },
          { name: '!=', label: 'is not equal to' },
          { name: '<', label: 'less than' },
          { name: '<=', label: 'less than or equal to' },
          { name: '>', label: 'greater than' },
          { name: '>=', label: 'greater than or equal to' },
          ...ops.in,
          ...ops.null,
        ]

      case 'date':
        return [
          { name: '=', label: 'on' },
          { name: '!=', label: 'not on' },
          { name: '<', label: 'before' },
          { name: '<=', label: 'on or before' },
          { name: '>', label: 'after' },
          { name: '>=', label: 'on or after' },
          { name: 'between', label: 'between' },
          { name: 'notBetween', label: 'not between' },
          ...ops.null,
        ]

      case 'boolean':
        return [...ops.is, ...ops.null]

      case 'array':
        return [...ops.in, ...ops.null]

      case 'object':
        return ops.null

      default:
        return []
    }
  }
