import { useMutation, useQueryClient } from '@tanstack/react-query'

import { getApiClient, getGenericClient } from '../../client'
import { getQueryKey } from '../../services'
import { handleHttpErrors } from '../../shared'
import { useQueryKeys } from '../useQueryKeys'
import { showErrorToast } from '@bpinternal/ui-kit'

type UpdateWorkspaceParams = {
  workspaceId: string
  requesterMemberId: string
  onSuccess?: () => Promise<void>
} & Parameters<ReturnType<typeof getGenericClient>['deleteWorkspaceMember']>['0']

export function useDeleteWorkspaceMember() {
  const queryClient = useQueryClient()
  const { getWorkspaceUsages, getAllWorkspaceUsages } = useQueryKeys()

  return useMutation({
    mutationFn: async ({ workspaceId, id }: UpdateWorkspaceParams) => {
      return await getApiClient(workspaceId).deleteWorkspaceMember({ id }).catch(handleHttpErrors)
    },
    onSuccess: async (_, { onSuccess, workspaceId, id, requesterMemberId }) => {
      if (id !== requesterMemberId) {
        const listMembersKey = getQueryKey('workspaces_/$workspaceId_/members', { workspaceId })

        await queryClient.invalidateQueries({ queryKey: listMembersKey })
        await queryClient.invalidateQueries({ queryKey: getWorkspaceUsages(workspaceId) })
        await queryClient.invalidateQueries({ queryKey: getAllWorkspaceUsages(workspaceId) })
      }

      await onSuccess?.()
    },
    onError: (error: Error) => {
      showErrorToast(error.message)
    },
  })
}
