import { Badge } from '@radix-ui/themes'
import clsx from 'clsx'
import { container } from './Enum.module.css'
import type { EnumCell } from '../../types'
import type { RenderCellProps } from 'react-data-grid'
import { baseColors, hashIdToNumber } from '../../../../utils'

type Props = EnumCell & { className?: string } & RenderCellProps<unknown>

export const EnumCellRenderer = ({ className, value, options, column, computed }: Props) => {
  // This is to give a unique color to each enum value while making sure the same color does not repeat for difference options. the value are offsetted by the column key to make sur different columns have different colors for the same enum value
  const { key } = column
  const colorOffset = hashIdToNumber(key, baseColors.length)
  const valueIndex = options.findIndex((option) => option.value === value)
  const color = baseColors[(colorOffset + 4 * valueIndex) % baseColors.length]

  const label = options[valueIndex]?.label ?? value

  return (
    <Badge color={color} size={'1'} className={clsx(className, container, { 'computed-cell': computed })}>
      {label}
    </Badge>
  )
}
