import { copyToClipboard, parseIdentifier } from '~/utils'
import { Prefixes } from '@bpinternal/const'
import { useMemo, type PropsWithChildren } from 'react'
import { useNavigate, NavigateOptions, useParams } from '@tanstack/react-router'
import { Color } from '../types'
import { DropdownMenu, type MenuItem } from '~/elementsv2'

const studioPrefixes = ['ins', 'nd', 'wf'] as const
type StudioPrefixes = (typeof studioPrefixes)[number]

type Props = PropsWithChildren<{
  value: string
  prefix?: Prefixes | 'url' | 'unknown' | StudioPrefixes
  color?: Color
  path?: string
  studioUrl?: string
  onClick?: (e: React.MouseEvent) => void
}>
type PropsPerPrefix = Partial<
  Record<
    Prefixes | 'unknown' | 'url' | StudioPrefixes,
    {
      eventsNavigationOptions?: NavigateOptions
      conversationsNavigationOptions?: NavigateOptions
      userNavigationOptions?: NavigateOptions
    }
  >
>

export const IdentifierDropdownMenu = ({
  value,
  prefix: providedPrefix,
  color = 'gray',
  children,
  path,
  studioUrl,
  ...props
}: Props) => {
  const navigate = useNavigate()

  const prefix = providedPrefix ?? parseIdentifier(value)

  const params = useParams({ strict: false })
  const baseEventsNavigationOptions = {
    params,
    to: '/workspaces/$workspaceId/bots/$botId/events',
  } as const

  const baseConversationsNavigationOptions = {
    params,
    to: '/workspaces/$workspaceId/bots/$botId/conversations',
  } as const

  const baseUserNavigationOptions = {
    params,
    to: '/workspaces/$workspaceId/bots/$botId/users',
  } as const

  const propsPerPrefix: PropsPerPrefix = useMemo(() => {
    return {
      conv: {
        eventsNavigationOptions: {
          ...baseEventsNavigationOptions,
          search: { conversationId: value },
        },
        conversationsNavigationOptions: {
          to: `${baseConversationsNavigationOptions.to}/$conversationId`,
          params: { ...baseConversationsNavigationOptions.params, conversationId: value },
          search: {},
        },
        userNavigationOptions: {
          ...baseUserNavigationOptions,
          search: { conversationId: value },
        },
      },
      msg: {
        eventsNavigationOptions: {
          ...baseEventsNavigationOptions,
          search: { messageId: value },
        },
        conversationsNavigationOptions: {
          to: '/workspaces/$workspaceId/bots/$botId/conversations/message/$messageId',
          params: { ...baseConversationsNavigationOptions.params, messageId: value },
          search: {},
        },
      },
      evt: {
        eventsNavigationOptions: {
          ...baseEventsNavigationOptions,
          search: { eventId: value },
        },
      },
      user: {
        eventsNavigationOptions: {
          ...baseEventsNavigationOptions,
          search: { userId: value },
        },
        conversationsNavigationOptions: {
          ...baseConversationsNavigationOptions,
          search: { participantIds: [value] },
        },
        userNavigationOptions: {
          ...baseUserNavigationOptions,
          search: { userId: value },
        },
      },
    }
  }, [value])

  const { conversationsNavigationOptions, eventsNavigationOptions, userNavigationOptions } =
    propsPerPrefix[prefix] ?? {}

  const navigationOptions = [
    ...(conversationsNavigationOptions
      ? [
          {
            type: 'item',
            content: 'View in Conversations',
            onSelect: () => navigate(conversationsNavigationOptions),
          } as const,
        ]
      : []),
    ...(eventsNavigationOptions
      ? [
          {
            type: 'item',
            content: 'View in Events',
            onSelect: () => navigate(eventsNavigationOptions),
          } as const,
        ]
      : []),
    ...(userNavigationOptions
      ? [
          {
            type: 'item',
            content: 'View in Users',
            onSelect: () => navigate(userNavigationOptions),
          } as const,
        ]
      : []),

    ...(prefix === 'url'
      ? [
          {
            type: 'item',
            content: 'Open in new tab',
            onSelect: () => window.open(value, '_blank'),
          } as const,
        ]
      : []),
    ...(studioUrl
      ? [
          {
            type: 'item',
            content: 'View in Studio',
            onSelect: () => window.open(studioUrl, '_blank'),
          } as const,
        ]
      : []),
  ]

  const menu: MenuItem[] = [
    ...navigationOptions,
    ...(navigationOptions.length ? [{ type: 'separator' as const }] : []),
    {
      type: 'item',
      content: 'Copy Path',
      hidden: !path,
      onSelect: (e) => void copyToClipboard(path ?? '', `path: ${path}`, { preventDefault: false, event: e }),
    },
    {
      type: 'item',
      content: 'Copy Value',
      onSelect: (e) => void copyToClipboard(value, 'id', { preventDefault: false, event: e }),
    },
  ]

  return (
    <DropdownMenu {...props} color={color} content={menu}>
      {children}
    </DropdownMenu>
  )
}
