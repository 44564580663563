import { useState } from 'react'
import { Spinner } from '~/elementsv2'
import cn from 'classnames'
import { Accept, useDropzone } from 'react-dropzone'
import { showErrorToast } from '@bpinternal/ui-kit'
import imageCompression from 'browser-image-compression'
import { Button } from '@bpinternal/ui-kit'

const MAX_UPLOAD_SIZE = 5 * 1024 * 1024 // 5MB
const MAX_COMPRESSED_SIZE = 20 * 1024 // 20KB
const ACCEPTED_FILES: Accept = {
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/svg+xml': ['.svg'],
  'image/png': ['.png'],
  'image/webp': ['.webp'],
}

// TODO Refactor this and ProfilePictureField to use the same component they have tiny differences
export const ImageUploader = ({ onUpload }: { onUpload: (url: string) => void }) => {
  const [isLoading, setIsLoading] = useState(false)

  const processFile = async (imageFile: File) => {
    if (imageFile.size > MAX_UPLOAD_SIZE) {
      showErrorToast(`Selected file can't be uploaded too large`)
    }

    setIsLoading(true)
    if (imageFile.size > MAX_COMPRESSED_SIZE) {
      try {
        const compressedFile = await imageCompression(imageFile, {
          maxSizeMB: MAX_COMPRESSED_SIZE / 1024 / 1024,
          maxWidthOrHeight: 256,
          useWebWorker: true,
          fileType: 'image/webp',
          initialQuality: 0.75,
        })
        imageFile = compressedFile
      } catch (error) {
        showErrorToast('An error occurred while compressing the image. Please try again')
        return
      }
    }

    const base64 = await imageCompression.getDataUrlFromFile(imageFile)
    setIsLoading(false)
    onUpload(base64) //TODO check what to do with this
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ACCEPTED_FILES,
    maxSize: MAX_UPLOAD_SIZE,
    multiple: false,
    onDropRejected: () => showErrorToast(`Selected file can't be uploaded, it might be too large or not an image`),
    onDrop: ([file]: File[]) => {
      if (!file) {
        return
      }
      void processFile(file)
    },
  })

  if (isLoading) {
    return (
      <div className="flex min-h-[10rem] items-center justify-center rounded-lg bg-opacity-80 p-8">
        <Spinner className="text-gray-9" size="2" />
      </div>
    )
  }

  return (
    <div
      className={cn('rounded border-2 border-dotted p-2', {
        'border-accent-9': isDragActive,
        'border-gray-4': !isDragActive,
      })}
    >
      <div
        className={cn('flex items-center justify-center rounded-lg px-8 py-10', {
          'bg-gray-2': isDragActive,
        })}
        contentEditable={false}
        {...getRootProps()}
      >
        <div className="flex flex-col items-center justify-center gap-2">
          <div className="text-center text-sm font-medium text-gray-6">
            {isDragActive ? 'Drop image here' : 'Drag and drop image here'}
          </div>
          <Button disabled={isDragActive} variant="minimal" size="2" children="Upload image" />
        </div>
      </div>
      <input {...getInputProps()} />
    </div>
  )
}

export default ImageUploader
