import { RuleGroupArray, RuleGroupType, RuleType } from 'react-querybuilder'
import { QBFields } from './types'
import { regexOperators, unescapeRegexCharacters } from './rule-processor'
import { levenshteinSimilarity } from './string'

// This is a rough parser to handle different casing & minor typo by the user in the manual query
export function correctFieldNamesInRules(rules: RuleGroupType | RuleType, fields: QBFields): RuleGroupType | RuleType {
  if ('rules' in rules) {
    return {
      ...rules,
      rules: rules.rules.map((rule) => correctFieldNamesInRules(rule, fields)) as RuleGroupArray,
    }
  } else {
    return {
      ...rules,
      value: fixRegexValue(rules),
      field: fixFieldName(rules.field, fields),
    }
  }
}

export const fixFieldName = (fieldName: string, fields: QBFields): string => {
  let bestMatch = fieldName
  let highestSimilarity = 0

  Object.values(fields).forEach((field) => {
    const similarity = levenshteinSimilarity(fieldName.toLowerCase(), field.name.toLowerCase())
    if (similarity > highestSimilarity && similarity > 0.8) {
      highestSimilarity = similarity
      bestMatch = field.name
    }
  })

  return bestMatch
}

const fixRegexValue = (rule: RuleType) => {
  return regexOperators.includes(rule.operator) && typeof rule.value === 'string'
    ? unescapeRegexCharacters(rule.value)
    : rule.value
}
