import { DateTime } from 'luxon'

export type DateFormatOptions = {
  format?: 'full' | 'long' | 'medium' | 'short'
  relative?: boolean
  includeTime?: boolean
}

export function formatDate(date: string | Date, options: DateFormatOptions = {}) {
  const { format = 'medium', relative = false, includeTime = false } = options

  const dateTime = typeof date === 'string' ? DateTime.fromISO(date) : DateTime.fromJSDate(date)

  if (!dateTime.isValid) {
    return 'Invalid date'
  }

  if (relative) {
    return dateTime.toRelative()
  }

  switch (format) {
    case 'full':
      return dateTime.toLocaleString({ ...DateTime.DATE_HUGE, ...(includeTime && DateTime.TIME_SIMPLE) })
    case 'long':
      return dateTime.toLocaleString({ ...DateTime.DATE_FULL, ...(includeTime && DateTime.TIME_SIMPLE) })
    case 'short':
      return dateTime.toLocaleString({ ...DateTime.DATE_SHORT, ...(includeTime && DateTime.TIME_SIMPLE) })
    default:
      return dateTime.toLocaleString({
        ...DateTime.DATE_MED,
        ...(includeTime && DateTime.TIME_SIMPLE),
      })
  }
}
