import React, { FC, useCallback, useMemo, useState } from 'react'
import { type ClientOutputs } from 'botpress-client'
import { getAllFilterFieldsForSchema, stripControlCharacters } from './json-schema-to-qb-fields'
import { getOperators } from './operators'
import { correctFieldNamesInRules } from './parser'
import { ruleProcessor } from './rule-processor'

import {
  parseMongoDB,
  Field,
  QueryBuilder,
  RuleGroupType,
  defaultValidator,
  formatQuery,
  Controls,
  Option,
} from 'react-querybuilder'
import { QBFields, QBResult } from './types.js'
import { Icon, Select, TextArea } from '~/elementsv2'
import { Flex, Tooltip } from '@radix-ui/themes'
import { Button, IconButton } from '@bpinternal/ui-kit'
import { X, PlusCircleIcon } from 'lucide-react'
import { CustomValueEditor } from './CustomValueEditor'

import './qb-style.css'

type FilterQueryBuilderProps = {
  schema: ClientOutputs['getTable']['table']['schema']
  initialQueryConfig: QBResult
  onCancel: () => void
  onSave: (newQueryConfig: QBResult) => void
}

export const FilterQueryBuilder: FC<FilterQueryBuilderProps> = ({ schema, initialQueryConfig, onSave, onCancel }) => {
  const fields = useMemo(() => getAllFilterFieldsForSchema(schema), [schema])

  const [manualQuery, setManualQuery] = useState<string | null>(null)
  const [isInManualEditMode, setIsInManualEditMode] = useState(false)
  const [tableFilter, setTableFilter] = useState<QBResult>(initialQueryConfig)
  const [query, setQuery] = useState<RuleGroupType | undefined>(
    TableQueryToRuleGroupType(initialQueryConfig.query, fields)
  )

  const saveManualQuery = () => {
    setTableFilter({ query: manualQuery, rulesCount: 0 })
    setQuery(TableQueryToRuleGroupType(manualQuery, fields))
    setIsInManualEditMode(false)
  }

  const onQueryChange = useCallback(
    (newQuery: RuleGroupType) => {
      setQuery(newQuery)

      if (newQuery.rules.length === 0) {
        setTableFilter({ query: null, rulesCount: 0, isValid: false })
        setManualQuery(null)
        return
      }
      try {
        const mongoQuery = stripControlCharacters(formatQuery(newQuery, { format: 'mongodb', ruleProcessor }))

        // the validation is handled by the queryBuilder component, but it just applies a css class, so we need to wait for the class to be applied before checking.
        setTimeout(() => {
          const isValid = document.querySelector('.queryBuilder-invalid') === null
          setTableFilter({ query: mongoQuery, rulesCount: newQuery.rules.length, isValid })
          setManualQuery(mongoQuery)
        }, 50)
      } catch (err) {
        setTableFilter?.({ query: null, rulesCount: 0, isValid: false })
        setManualQuery(null)
      }
    },
    [setTableFilter]
  )

  const hasNoRules = query?.rules.length === 0

  const controlElements = useMemo(
    () =>
      ({
        valueEditor: CustomValueEditor,
        fieldSelector: ({ handleOnChange, value, options }) => (
          <Select
            className={'w-36'}
            size={'2'}
            value={value}
            onValueChange={handleOnChange}
            items={options.map((option) => ({
              type: 'item',
              value: (option as Field<string, string, string, Option<string>, Option<string>>).name,
              content: option.label,
            }))}
          />
        ),
        operatorSelector: ({ handleOnChange, value, options }) => (
          <Select
            className={'w-36'}
            size={'2'}
            value={value}
            onValueChange={handleOnChange}
            items={options.map((option) => ({
              type: 'item',
              value: (option as Field<string, string, string, Option<string>, Option<string>>).name,
              content: option.label,
            }))}
          />
        ),
        combinatorSelector: ({ handleOnChange, value, options }) =>
          hasNoRules ? null : (
            <Select
              className={'w-36'}
              size={'2'}
              value={value}
              onValueChange={handleOnChange}
              items={options.map((option) => ({
                type: 'item',
                value: (option as Field<string, string, string, Option<string>, Option<string>>).name,
                content: option.label,
              }))}
            />
          ),
        addRuleAction: ({ handleOnClick }) => (
          <Button
            color={'gray'}
            size={'2'}
            variant={'minimal'}
            leading={<Icon size={'2'} icon={PlusCircleIcon} color={'gray'} />}
            onClick={handleOnClick}
          >
            Rule
          </Button>
        ),
        addGroupAction: ({ handleOnClick }) =>
          hasNoRules ? null : (
            <Button
              color={'gray'}
              size={'2'}
              variant={'minimal'}
              leading={<Icon size={'2'} icon={PlusCircleIcon} color={'gray'} />}
              onClick={handleOnClick}
            >
              Group
            </Button>
          ),
        removeRuleAction: ({ handleOnClick }) => (
          <IconButton size={'1'} variant={'ghost'} color={'gray'} icon={X} onClick={handleOnClick} />
        ),
        removeGroupAction: ({ handleOnClick }) => (
          <IconButton size={'1'} variant={'ghost'} color={'gray'} icon={X} onClick={handleOnClick} />
        ),
      }) satisfies Partial<Controls>,
    [hasNoRules]
  )

  return (
    <div className="w-[700px]">
      {isInManualEditMode ? (
        <>
          <div className="max-h-[700px] overflow-y-auto p-2">
            <TextArea
              size={'2'}
              label="Manual Filter Editing"
              placeholder="{ fieldName: { $eq: 'test' } }"
              value={manualQuery ?? undefined}
              onChange={(e) => setManualQuery(e.currentTarget.value)}
            />
          </div>
          <Flex justify="end">
            <Flex gap="2" className="p-2" align="center">
              <Button variant="outline" onClick={() => setIsInManualEditMode(false)}>
                Cancel
              </Button>
              <Button onClick={saveManualQuery}>Save</Button>
            </Flex>
          </Flex>
        </>
      ) : (
        <>
          <div className="max-h-[700px] overflow-y-auto p-2">
            <QueryBuilder
              controlElements={controlElements}
              fields={fields as Field}
              getOperators={getOperators(fields ?? {})}
              query={query}
              onQueryChange={onQueryChange}
              addRuleToNewGroups
              validator={defaultValidator}
            />
          </div>
          <Flex justify="between">
            <Flex gap="2" className="p-2" align="center">
              <Button variant="soft" color="gray" onClick={() => setIsInManualEditMode(true)}>
                Edit manually
              </Button>
            </Flex>
            <Flex gap="2" className="p-2" align="center">
              <Button variant="soft" color="gray" onClick={onCancel}>
                Cancel
              </Button>
              <Tooltip content="The filter is invalid, cannot apply" open={tableFilter.isValid ? false : undefined}>
                <Button disabled={!tableFilter.isValid} onClick={() => onSave(tableFilter)}>
                  Apply Filter
                </Button>
              </Tooltip>
            </Flex>
          </Flex>
        </>
      )}
    </div>
  )
}

const TableQueryToRuleGroupType = (query: QBResult['query'], fields: QBFields): RuleGroupType | undefined => {
  if (!query) {
    return undefined
  }

  try {
    const parsedRules = parseMongoDB(query, { listsAsArrays: true })
    return correctFieldNamesInRules(parsedRules, fields) as RuleGroupType
  } catch (err) {
    return undefined
  }
}
