import React from 'react'
import { Button, Container, Section } from '@radix-ui/themes'
import { createRouter, Link, RouterProvider } from '@tanstack/react-router'
import { routeTree } from './routeTree.gen'
import { queryClient } from './providers/ReactQuery'
import { EmptyState, BpSpinner } from './elementsv2'
import AccessDeniedIcon from '~/assets/website-maintenance-15.svg?react'
import UnknownErrorIcon from '~/assets/website-maintenance-29.svg?react'
import NotFoundIcon from '~/assets/programming-30.svg?react'
import { isApiError } from 'botpress-client'
import { match } from 'ts-pattern'

const router = createRouter({
  routeTree,
  defaultPreload: 'intent',
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
  defaultPendingMinMs: 0,
  context: {
    queryClient, // import for now, will fix later
  },
  defaultPendingComponent: () => {
    return (
      <div className="flex h-[calc(100vh-32rem)] items-center justify-center">
        <div className="size-20">
          <BpSpinner />
        </div>
      </div>
    )
  },
  defaultErrorComponent: ({ error }) => {
    const err = isApiError(error) ? error : { message: error?.message ?? 'An unknown error occurred' }

    return (
      <Section>
        <Container size={'2'}>
          {match(err)
            .with({ code: 403 }, () => (
              <EmptyState
                iconSize={6}
                icon={AccessDeniedIcon}
                title={'Access Denied'}
                description={
                  'You don’t have permission to access this content. Please check your access rights and verify your credentials, or contact support for assistance.'
                }
                primaryAction={
                  <Link to="/">
                    <Button>Back to Home</Button>
                  </Link>
                }
              />
            ))
            .with({ code: 404 }, () => (
              <EmptyState
                iconSize={6}
                icon={NotFoundIcon}
                title={'Not Found'}
                description={
                  'The requested resource cannot be found. It may have been removed or is temporarily unavailable. Please verify the details and try again. For assistance, contact support.'
                }
                primaryAction={
                  <Link to="/">
                    <Button>Back to Home</Button>
                  </Link>
                }
              />
            ))
            .otherwise(() => (
              <EmptyState
                iconSize={6}
                icon={UnknownErrorIcon}
                title={'Unexpected Error Occured'}
                description={
                  'An issue has occurred. Please retry in a few moments. If the problem persists, contact our support team for assistance.'
                }
                primaryAction={
                  <Link to="/">
                    <Button>Back to Home</Button>
                  </Link>
                }
              />
            ))}
        </Container>
      </Section>
    )
  },
})

// Register things for typesafety
declare module '@tanstack/react-router' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Register {
    router: typeof router
  }
}

export const Router = () => {
  return <RouterProvider router={router} />
}
