// TODO: This has been copy/pasted to ui-kit, should delete

import { baseColors, regularColors } from '../types'

export function hashIdToNumber(id: string, max = 100) {
  return id.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0) % max
}

export function hashIdToColor(id: string, colors?: 'all' | 'regular') {
  if (colors === 'regular') {
    return regularColors[hashIdToNumber(id, baseColors.length - 1) + 1] ?? baseColors[0]
  }
  return baseColors[hashIdToNumber(id, baseColors.length)] ?? baseColors[0]
}
