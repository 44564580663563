import React from 'react'
import { Flex } from '@radix-ui/themes'
import { BannerPortal } from '../../../componentsV2'
import { Callout } from '@bpinternal/ui-kit'

export const BillingReadonlyCallout = () => {
  return (
    <BannerPortal>
      <Callout className="m-2">
        <Flex justify={'center'}>
          Billing cannot be modified. Please contact your Botpress representative to make changes.
        </Flex>
      </Callout>
    </BannerPortal>
  )
}
