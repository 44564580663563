import { Text } from '@radix-ui/themes'
import clsx from 'clsx'
import { container } from './Text.module.css'
import type { TextCell } from '../../types'
import type { RenderCellProps } from 'react-data-grid'
import { useIntersectionObserver } from 'usehooks-ts'
import { HoverCard } from '../../../HoverCard'

type Props = TextCell & { className?: string } & RenderCellProps<unknown>

export const TextCellRenderer = ({ className, value, content, computed }: Props) => {
  const { isIntersecting: contained, ref } = useIntersectionObserver({ threshold: 1 })

  return (
    <>
      <Text ref={ref} size={'1'} className={clsx(className, container, { 'computed-cell': computed })}>
        {!contained ? (
          <HoverCard maxWidth={'400px'} size={'1'} trigger={<Text truncate>{content ?? value}</Text>}>
            <Text size={'1'}>{content ?? value}</Text>
          </HoverCard>
        ) : (
          (content ?? value)
        )}
      </Text>
    </>
  )
}
