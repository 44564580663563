import { Flex, TextField } from '@radix-ui/themes'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { useCallback, useEffect, useRef, useState } from 'react'
import { cn } from '~/utils'
import { DropdownMenu, Icon, MenuItem } from '~/elementsv2'
import { HiMagnifyingGlass, HiMiniChevronDown } from 'react-icons/hi2'
import { Button } from '@bpinternal/ui-kit'
import { FILTER_SEPARATOR, parseParams, parseSearchString } from './parseParams'

export type FilterMenuInput = {
  name: string
  type?: 'string' | 'object' | 'array'
  value?: string | FilterMenuInput[]
  paramName?: string
  icon?: React.ReactNode
}

type Props = { className?: string; filters: FilterMenuInput[] }

export const QueryParamBuilder = ({ className, filters, ...props }: Props) => {
  const searchInputRef = useRef<HTMLInputElement>(null)
  const queryParams = useSearch({ strict: false })
  const [filterInput, setFilterInput] = useState(parseParams(queryParams))

  const navigate = useNavigate()
  const updateSearchParams = (searchParams: Record<string, string | string[] | Record<string, string>>) => {
    void navigate({
      to: '.',
      params: (current: Record<string, any>) => current,
      search: () => searchParams,
    })
  }

  const addFilter = useCallback(
    ({ filter, value }: { filter: string; value?: string }) => {
      setFilterInput((prevInput) => `${prevInput.trim()} ${filter}${FILTER_SEPARATOR}${value ?? ''}`.trim())
    },
    [setFilterInput]
  )

  useEffect(() => {
    setFilterInput(parseParams(queryParams))
  }, [queryParams])

  function buildMenuItem(filter: FilterMenuInput): MenuItem {
    const { name, value, paramName, icon } = filter
    if (Array.isArray(value)) {
      return {
        type: 'submenu',
        content: name,
        items: value.map((f) => buildMenuItem(f)),
        leadingIcon: icon,
      }
    }
    return {
      type: 'item',
      content: name,
      leadingIcon: icon,
      onSelect: () => {
        addFilter({ filter: paramName ?? '', value })
      },
    }
  }

  const searchFilterMenu: MenuItem[] = [{ type: 'label', label: 'Filter by' }, ...filters.map(buildMenuItem)]

  return (
    <Flex {...props} className={cn(className)} gap={'2'}>
      <TextField.Root
        ref={searchInputRef}
        value={filterInput}
        onChange={(e) => {
          setFilterInput(e.target.value)
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            const { parsedParams } = parseSearchString(filterInput, filters)
            updateSearchParams(parsedParams)
          }
        }}
        className="grow"
      >
        <TextField.Slot className="pl-0">
          <DropdownMenu
            content={searchFilterMenu}
            color="gray"
            size={'2'}
            onCloseAutoFocus={(e) => {
              e.preventDefault()
              searchInputRef.current?.focus()
              searchInputRef.current?.setSelectionRange(filterInput.length, filterInput.length)
            }}
          >
            <Button
              variant="surface"
              color="gray"
              className="rounded-r-none"
              trailing={<Icon icon={HiMiniChevronDown} />}
              onClick={() => {}}
            >
              Filters
            </Button>
          </DropdownMenu>
          <Icon icon={HiMagnifyingGlass} color="gray" />
        </TextField.Slot>
      </TextField.Root>
    </Flex>
  )
}
