/* eslint-disable no-console */
import { Flex, TextArea } from '@radix-ui/themes'
import { useParams } from '@tanstack/react-router'
import { useState } from 'react'
import { useCurrentRouteId, useCurrentUser } from '~/hooks'
import { captureException, captureFeedback, captureMessage, setTags } from '@sentry/react'
import { DialogFooter, showDialog } from '@bpinternal/ui-kit'

export const showBugReportDialog = ({
  error,
  onSubmit,
}: {
  error?: any
  onSubmit?: (props: { id: string; message: string }) => void
}) =>
  showDialog(({ close }) => <BugReportDialog close={close} error={error} onSubmit={onSubmit} />, {
    title: 'Report a bug',
  })

export const BugReportDialog = ({
  close,
  error,
  onSubmit,
}: {
  error?: any
  close: () => void
  onSubmit?: (props: { id: string; message: string }) => void
}) => {
  const [message, setMessage] = useState('')
  const params = useParams({ strict: false })
  const routeId = useCurrentRouteId()
  const { profilePicture: _, ...user } = useCurrentUser()

  return (
    <Flex direction={'column'} gap={'2'}>
      <Flex direction={'column'} gap={'4'}>
        <TextArea
          size={'1'}
          className="h-24 grow"
          value={message}
          onChange={(e) => {
            setMessage(e.target.value)
          }}
          placeholder="Describe the bug you're experiencing..."
        />
      </Flex>

      <DialogFooter
        disabled={!message}
        confirmLabel="Send"
        onConfirm={() => {
          const tags = { routeId, ...params }
          const currentUser = { email: user.email, id: user.id, username: user.displayName }

          setTags(tags)

          if (error) {
            const eventId = captureException(error, { tags: { ...tags, userMessage: message } })
            onSubmit?.({ id: eventId, message })
          } else {
            const eventId = captureMessage(message)
            captureFeedback(
              {
                message,
                associatedEventId: eventId,
                name: user.displayName,
                email: user.email,
                source: 'Bug report dialog',
              },
              { includeReplay: true, captureContext: { tags, user: currentUser } }
            )
          }
          close()
        }}
      />
    </Flex>
  )
}
