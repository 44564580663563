import clsx from 'clsx'
import type { DateCell } from '../../types'
import type { RenderCellProps } from 'react-data-grid'
import { Text } from '@radix-ui/themes'
import { formatDate } from '@botpress/shared'

type Props = DateCell & { className?: string } & RenderCellProps<unknown>

export const DateCellRenderer = ({ className, value, computed }: Props) => {
  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)

  return (
    <Text size={'1'} className={clsx(className, { 'computed-cell': computed })}>
      {formatDate(value, { format: 'short', includeTime: true, relative: value > yesterday })}
    </Text>
  )
}
