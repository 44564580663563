import { useMutation, useQueryClient } from '@tanstack/react-query'
import { resolveKey, setPreference, SetPreferencePropsUnion } from '../../services/preferences'
import { getQueryOptions } from '~/services'

export const useSetPreferences = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (props: SetPreferencePropsUnion) => {
      return setPreference(props)
    },
    onSuccess: async (_, props) => {
      const { value, ...rest } = props
      const { queryKey } = getQueryOptions('preferences/$path', {
        path: resolveKey(rest) as any,
        params: { botId: 'botId', workspaceId: 'workspaceId', tableId: 'tableId' }, // this is not used, the typings are just incorrect
      })

      queryClient.setQueryData(queryKey, value)
    },
    onError: (error) => {
      console.error('Error updating user preferences', error)
    },
  })
}
