import { Link, createFileRoute } from '@tanstack/react-router'
import { List, EmptyState } from '~/elementsv2'
import { Badge, Flex, Text } from '@radix-ui/themes'
import { Boundary, FilterMenuInput, QueryParamBuilder, UserAvatar } from '~/componentsV2'
import { auditMessages } from '~/features/audits/components'
import { DateTime } from 'luxon'
import { Color, Variant } from '~/types'
import { Changelog } from '~/assets'
import { Skeleton } from '~/elements'
import { ClientOutputs } from 'botpress-client'
import { useSuspenseInfiniteQuery } from '@tanstack/react-query'
import { listAuditsInfiniteQueryOptions } from '~/queries'
import { useInfiniteScroll } from '~/hooks/useInfiniteScroll'
import { Button } from '@bpinternal/ui-kit'
import { useSuspenseQuery } from '~/services'

export const Route = createFileRoute('/workspaces/$workspaceId/settings/audits')({
  validateSearch: (search): { action?: string; userId?: string } => search,
  component: Component,
})

function Component() {
  return (
    <Boundary className="h-96" suspenseFallback={<List items={Array(15).fill(null)}>{<AuditRowSkeleton />}</List>}>
      <Audit />
    </Boundary>
  )
}

const Audit = () => {
  const { workspaceId } = Route.useParams()
  const { action: actionFilter, userId: userIdFilter } = Route.useSearch()
  const {
    data: fetchedAudits,
    hasNextPage,
    fetchNextPage,
    isFetching,
  } = useSuspenseInfiniteQuery(listAuditsInfiniteQueryOptions({ workspaceId }))
  const navigate = Route.useNavigate()

  const workspaceMembers = useSuspenseQuery('workspaces_/$workspaceId_/members', { workspaceId }).data

  const bottomRef = useInfiniteScroll(fetchNextPage, hasNextPage)
  const audits = fetchedAudits?.pages.flatMap((p) => p.records)

  const filters: FilterMenuInput[] = [
    {
      name: 'Action Taken',
      type: 'string',
      paramName: 'action',
      value: Object.entries(auditMessages).map(([value, { label }]) => ({
        name: label,
        value,
        paramName: 'action',
      })),
    },
    {
      name: 'Workspace Member',
      type: 'string',
      paramName: 'userId',
      value: workspaceMembers.map((member) => ({
        name: member.email,
        value: member.userId,
        paramName: 'userId',
      })),
    },
  ]

  const filterdAudits = audits.filter((audit) => {
    let match = true
    if (actionFilter) {
      match = match && audit.action === actionFilter
    }
    if (userIdFilter) {
      match = match && audit.userId === userIdFilter
    }
    return match
  })

  if (audits.length === 0) {
    return (
      <EmptyState
        p={'8'}
        iconSize={6}
        icon={Changelog}
        title="You don't have any audits"
        description="You have not performed any actions that are audited. Keep using Botpress to see your actions here."
        primaryAction={
          <Link to="/workspaces/$workspaceId" params={{ workspaceId }}>
            <Button size={'2'}>Back to workspace</Button>
          </Link>
        }
      />
    )
  }

  return (
    <Flex direction={'column'} gap={'4'}>
      <QueryParamBuilder filters={filters} />
      {filterdAudits.length > 0 ? (
        <>
          <List items={filterdAudits}>{(audit) => <AuditRow record={audit} />}</List>
          <div ref={bottomRef} />
          {isFetching && <AuditRowSkeleton />}
          <Button
            mt={'4'}
            m={'auto'}
            variant="soft"
            size="1"
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage}
            loading={isFetching}
          >
            Load more items
          </Button>
        </>
      ) : (
        <Flex direction={'column'} align={'center'} justify={'center'} p={'8'} gap="3">
          <Text color="gray">None of {audits.length} audits matching your filters.</Text>
          <Flex gap={'4'}>
            {hasNextPage && (
              <Button onClick={() => fetchNextPage()} loading={isFetching}>
                Load more items
              </Button>
            )}
            <Button
              variant="outline"
              color="gray"
              onClick={() => {
                void navigate({
                  to: '.',
                  params: (current: Record<string, any>) => current,
                  search: () => ({ action: undefined, userId: undefined }),
                })
              }}
            >
              Clear filters
            </Button>
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}

const AuditRow = ({ record }: { record: ClientOutputs['getAuditRecords']['records'][number] }) => {
  const message = auditMessages[record.action] ?? auditMessages.UNKNOWN
  const { workspaceId } = Route.useParams()
  const auditDate = DateTime.fromISO(record.recordedAt).toLocaleString(DateTime.DATETIME_MED)

  return (
    <Flex align={'center'} p={'4'} gap={'4'}>
      <UserAvatar size={'2'} userId={record.userId ?? undefined} workspaceId={workspaceId} />
      <Flex direction={'column'} gap={'1'}>
        <Text size={'2'} color="gray">
          <b>{record.userEmail}</b> {message.render(record)}
        </Text>
        <Text color="gray" size={'1'}>
          {auditDate}
        </Text>
      </Flex>
      <Badge ml={'auto'} variant="surface" color={variantToColor(message.variant)}>
        {message.label}
      </Badge>
    </Flex>
  )
}

const AuditRowSkeleton = () => {
  return (
    <Flex align={'center'} p={'4'} gap={'4'}>
      <Skeleton height={32} width={32} />
      <Flex direction={'column'} gap={'2'}>
        <Skeleton height={8} width={400} />
        <Skeleton height={8} width={180} />
      </Flex>
      <Skeleton className="ml-auto" width={96} />
    </Flex>
  )
}

function variantToColor(variant: Variant): Color {
  switch (variant) {
    case 'default':
      return 'gray' as const
    case 'info':
      return 'blue' as const
    case 'success':
      return 'green' as const
    case 'warning':
      return 'yellow' as const
    case 'danger':
      return 'red' as const
    default:
      return 'gray' as const
  }
}
