import { Text } from '@radix-ui/themes'
import clsx from 'clsx'
import { container } from './Number.module.css'
import type { NumberCell } from '../../types'
import type { RenderCellProps } from 'react-data-grid'

type Props = NumberCell & { className?: string } & RenderCellProps<unknown>

export const NumberCellRenderer = ({ className, value, content, computed }: Props) => {
  return (
    <Text size={'1'} className={clsx(className, container, { 'computed-cell': computed })}>
      {content ?? value}
    </Text>
  )
}
