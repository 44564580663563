import { Text } from '@radix-ui/themes'
import type { NullCell } from '../../types'
import type { RenderCellProps } from 'react-data-grid'
import { text } from './Null.module.css'
import clsx from 'clsx'

type Props = NullCell & { className?: string } & RenderCellProps<unknown>

export const NullCellRenderer = ({ className, computed }: Props) => {
  return (
    <Text size={'1'} color="gray" weight={'light'} className={clsx(className, text, { 'computed-cell': computed })}>
      NULL
    </Text>
  )
}
