import clsx from 'clsx'
import { container } from './Boolean.module.css'
import type { BooleanCell } from '../../types'
import type { RenderCellProps } from 'react-data-grid'
import { Checkbox } from '../../../Checkbox'
import { Flex } from '@radix-ui/themes'

type Props = BooleanCell & { className?: string } & RenderCellProps<unknown>

export const BooleanCellRenderer = ({ className, value, computed }: Props) => {
  return (
    <Flex>
      <Checkbox className={clsx(className, container, { 'computed-cell': computed })} checked={value} />
    </Flex>
  )
}
