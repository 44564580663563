import { createFileRoute, Link as RouterLink } from '@tanstack/react-router'
import { EmptyState } from '../elementsv2'
import Icon from '~/assets/website-maintenance-15.svg?react'
import { Container, Section } from '@radix-ui/themes'
import { z } from 'zod'
import { match, P } from 'ts-pattern'
import { useLocalStore } from '~/stores'
import { Button } from '@bpinternal/ui-kit'

const forbiddenPageSchema = z.object({
  botId: z.string().optional(),
  workspaceId: z.string().optional(),
})

export const Route = createFileRoute('/forbidden')({
  validateSearch: forbiddenPageSchema,
  component: Component,
})

function Component() {
  const searchParams = Route.useSearch()
  const setLastActiveWorkspaceId = useLocalStore((s) => s.setLastActiveWorkspaceId)

  const primaryAction = match(searchParams)
    .with({ botId: P.string, workspaceId: P.string }, ({ botId, workspaceId }) => (
      <RouterLink to="/workspaces/$workspaceId/bots/$botId" params={{ botId, workspaceId }}>
        <Button>Back to Bot</Button>
      </RouterLink>
    ))
    .with({ workspaceId: P.string }, ({ workspaceId }) => (
      <RouterLink to="/workspaces/$workspaceId" params={{ workspaceId }}>
        <Button>Back to Workspace</Button>
      </RouterLink>
    ))
    .otherwise(() => (
      <RouterLink to="/">
        <Button
          onClick={() => {
            setLastActiveWorkspaceId('')
          }}
        >
          Back to Home
        </Button>
      </RouterLink>
    ))

  return (
    <Section>
      <Container size={'2'}>
        <EmptyState
          iconSize={6}
          icon={Icon}
          title={'Access Denied'}
          description={
            'You don’t have permission to access this content. Please check your access rights and verify your credentials, or contact support for assistance.'
          }
          primaryAction={primaryAction}
        />
      </Container>
    </Section>
  )
}
