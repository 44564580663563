import { useMutation, useQueryClient } from '@tanstack/react-query'
import { getApiClient } from '../../client'
import { showSuccessToast } from '@bpinternal/ui-kit'
import { handleHttpErrors } from '../../shared'
import { useQueryKeys } from '../useQueryKeys'

type ChangeAiSpendQuotaParams = { workspaceId: string; onSuccess?: () => void; monthlySpendingLimit?: number }

export function useChangeAiSpendQuota() {
  const queryClient = useQueryClient()
  const { getWorkspaceUsage } = useQueryKeys()

  return useMutation({
    mutationFn: async ({ workspaceId, monthlySpendingLimit }: ChangeAiSpendQuotaParams) => {
      // TODO: popup form to contact us for spending limit increase
      if (monthlySpendingLimit === undefined) {
        throw new Error('Undefined spending limit')
      }
      return await getApiClient(workspaceId).changeAISpendQuota({ monthlySpendingLimit }).catch(handleHttpErrors)
    },
    onSuccess: async (_, { onSuccess, workspaceId }) => {
      await queryClient.invalidateQueries({
        queryKey: getWorkspaceUsage(workspaceId, 'ai_spend'),
      })
      showSuccessToast('Your AI spend limit has been updated successfully.')
      onSuccess?.()
    },
  })
}
