import { createFileRoute, Link as RouterLink } from '@tanstack/react-router'
import { Badge, Flex, IconButton, Separator, Text, TextField, Tooltip } from '@radix-ui/themes'
import { BotIcon, Boundary, Page } from '~/componentsV2'
import { EmptyState, List, Icon, Link } from '~/elementsv2'
import { getCdmStudioUrl } from '~/shared'
import { SidebarLayout } from '~/layouts'
import { useAnalyticsOverview, useIsAuthorized, useRenameBot } from '~/hooks'
import { AreaChart } from '@tremor/react'
import { useSuspenseQuery } from '@tanstack/react-query'
import { botQueryOptions, listAllIssuesQueryOptions } from '~/queries'
import { DateTime } from 'luxon'
import { IssueIcon } from '~/features/issues/componentsV2/IssueIcon'
import EmptyChartIcon from '~/assets/data-analytics-16.svg?react'
import EmptyIssuesIcon from '~/assets/programming-23.svg?react'
import { cn } from '~/utils'
import { IntegrationIcon } from '~/features/integrations/components'
import { ruby, blue, yellow } from '@radix-ui/colors'
import { Fragment, useState } from 'react'
import { HiOutlineCubeTransparent, HiOutlineGlobeAlt } from 'react-icons/hi2'
import { Card, Button } from '@bpinternal/ui-kit'

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/overview')({
  component: Component,
})

function Component() {
  const { user } = Route.useRouteContext()
  const { workspaceId, botId } = Route.useParams()
  const bot = useSuspenseQuery(botQueryOptions({ botId, workspaceId })).data
  const [newName, setNewName] = useState(bot.name)
  const [isEditing, setIsEditing] = useState(false)
  const { mutate: renameBot, isPending } = useRenameBot()
  const isAuthorized = useIsAuthorized({ workspaceId, userId: user.id })

  const isAuthorizedToOpenStudio = isAuthorized('studio.view') || isAuthorized('studio.edit')

  const handleRename = () => {
    if (newName !== bot.name) {
      renameBot({ botId: bot.id, workspaceId, name: newName })
    }
    setIsEditing(false)
  }

  return (
    <Page
      title={
        <Flex align={'center'} gap={'2'}>
          <BotIcon size="3" id={bot.id} />
          {isEditing ? (
            <TextField.Root
              size={'3'}
              value={newName}
              ref={(el) => el?.focus()}
              onChange={(e) => {
                setNewName(e.target.value)
              }}
              onBlur={handleRename}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleRename()
                }
              }}
              placeholder="Bot name"
            />
          ) : (
            <Text
              size="6"
              weight={isPending ? 'medium' : 'bold'}
              color={isPending ? 'gray' : undefined}
              className={cn('border-gray-7', { 'hover:border-b': !isPending })}
              onClick={() => {
                setIsEditing(true)
              }}
            >
              {isPending ? newName : bot.name}
            </Text>
          )}
        </Flex>
      }
      actions={
        <Tooltip
          content="You do not have access to this feature. Contact the owner or administrator(s) of this workspace to gain access."
          open={isAuthorizedToOpenStudio ? false : undefined}
          aria-disabled={isAuthorizedToOpenStudio}
        >
          <a
            href={isAuthorizedToOpenStudio ? getCdmStudioUrl(bot.id) : ''}
            target="_blank"
            rel="noopener noreferrer"
            className="ml-auto"
          >
            <Button disabled={!isAuthorizedToOpenStudio}>Edit in studio</Button>
          </a>
        </Tooltip>
      }
    >
      <SidebarLayout
        main={
          <Flex direction={'column'} gap={'8'}>
            <Flex direction={'column'} gap={'4'}>
              <Text size="2" weight="medium">
                About your bot
              </Text>
              <Boundary className="h-80">
                <BotAnalytics />
              </Boundary>
            </Flex>
            <Flex direction={'column'} gap={'4'}>
              <Text size="2" weight="medium">
                Recent Issues
              </Text>
              <Boundary className="h-64">
                <BotIssues />
              </Boundary>
            </Flex>
          </Flex>
        }
        rightSidebar={<BotIntegrations />}
      />
    </Page>
  )
}

const BotAnalytics = () => {
  const { botId, workspaceId } = Route.useParams()
  const analytics = useAnalyticsOverview({ botId, workspaceId }).data.records.map((record) => ({
    Date: DateTime.fromISO(record.endDateTimeUtc).toFormat('LLL d, yyyy'),
    Messages: record.messages,
    Sessions: record.sessions,
    'New Users': record.newUsers,
  }))

  return (
    <Card className="relative">
      {analytics.length > 3 ? (
        <AreaChart
          data={analytics}
          index={'Date'}
          categories={['Messages', 'Sessions', 'New Users']}
          colors={[ruby.ruby8, blue.blue8, yellow.yellow8]}
          showGridLines={false}
          curveType="monotone"
          showXAxis={false}
          showYAxis={false}
        />
      ) : (
        <EmptyState
          icon={({ className, ...props }) => <EmptyChartIcon className={cn('-scale-x-100', className)} {...props} />}
          iconSize={4}
          className="h-80"
          title="You don't have any analytics!"
          description="Start using your bot, an analytics overview will be available here."
        />
      )}
    </Card>
  )
}

const BotIssues = () => {
  const { botId, workspaceId } = Route.useParams()
  const issues = useSuspenseQuery(listAllIssuesQueryOptions({ botId, workspaceId })).data
  return (
    <Flex direction="column" gap="4">
      {issues.length !== 0 ? (
        <List items={issues}>
          {(issue) => (
            <Flex p={'4'} gap={'4'}>
              <Icon
                variant="soft"
                size="4"
                className="flex-none"
                icon={(props) => <IssueIcon code={issue.code} {...props} />}
              />
              <Flex direction={'column'}>
                <Flex align={'baseline'} gap={'3'}>
                  <Link
                    to="/workspaces/$workspaceId/bots/$botId/issues"
                    params={{ workspaceId, botId }}
                    search={() => ({ issueId: issue.id })}
                  >
                    <Text weight={'medium'}>{issue.title}</Text>
                  </Link>
                  <Text size={'1'} color="gray">
                    {DateTime.fromISO(issue.lastSeenAt).toRelative()}
                  </Text>
                </Flex>
                <Text size={'2'} color="gray">
                  {issue.description}
                </Text>
              </Flex>
            </Flex>
          )}
        </List>
      ) : (
        <Card>
          <EmptyState
            icon={EmptyIssuesIcon}
            className="h-64"
            title="No issues!"
            description="Your bot is running smoothly. No issues to show here."
          />
        </Card>
      )}
    </Flex>
  )
}

const BotIntegrations = () => {
  const { botId, workspaceId } = Route.useParams()
  const bot = useSuspenseQuery(botQueryOptions({ botId, workspaceId })).data

  //TODO: handle the case where someone has 15+ integrations, we need to add a "show more" button

  const integrations = [
    ...Object.entries(bot?.integrations ?? {}).map(([botIntegrationId, integration]) => ({
      botIntegrationId,
      ...integration,
    })),
  ].sort((a, b) => (a.enabled === b.enabled ? 0 : a.enabled ? -1 : 1))

  return (
    <Flex direction={'column'} gap={'3'}>
      <Flex align={'start'} justify={'between'}>
        <Text size={'2'} weight="medium" className={cn({ invisible: integrations.length === 0 })}>
          Installed integrations
        </Text>
        <Tooltip content="Find more integrations" delayDuration={200} side="left">
          <RouterLink to="/hub" params={{}}>
            <IconButton variant="ghost">
              <HiOutlineGlobeAlt className="h-5 w-5 " />
            </IconButton>
          </RouterLink>
        </Tooltip>
      </Flex>
      {integrations.length > 0 ? (
        integrations.map((integration) => {
          return (
            <Fragment key={integration.id}>
              <Flex direction={'column'} gap={'2'} className="w-full justify-start p-3 text-gray-12">
                <Flex align={'center'} gap={'2'}>
                  <IntegrationIcon className="h-4 w-4 saturate-[75%]" iconUrl={integration.iconUrl} />
                  <Text size={'2'}>{integration.title}</Text>
                  <Badge ml="auto" color={integration.enabled ? 'grass' : 'gray'}>
                    {integration.enabled ? 'enabled' : 'disabled'}
                  </Badge>
                </Flex>
                {integration.description && (
                  <Text color="gray" align={'left'} size={'1'}>
                    {integration.description}
                  </Text>
                )}
              </Flex>
              <Separator size={'4'} />
            </Fragment>
          )
        })
      ) : (
        <EmptyState
          className=""
          p={'6'}
          mx={'auto'}
          icon={({ className, ...props }) => (
            <HiOutlineCubeTransparent strokeWidth="1" className={cn('text-accent-10', className)} {...props} />
          )}
          iconSize={3}
          title="No integrations!"
          description="This bot does not have any integrations installed."
          secondaryAction={
            <Link to={'/hub'} params={{}} size={'2'}>
              Browse Hub
            </Link>
          }
        />
      )}
    </Flex>
  )
}
