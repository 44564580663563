import '~/features/analytics/styles/gridLayout.css'
import { CustomAnalytics } from '../../../../../features/analytics/components'
import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'

const oneWeekAgo = new Date()
oneWeekAgo.setDate(oneWeekAgo.getDate() - 7)

const analyticsSearchSchema = z.object({
  startDate: z.string().datetime().catch(oneWeekAgo.toISOString()),
  endDate: z.string().datetime().catch(new Date().toISOString()),
})

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/analytics')({
  validateSearch: analyticsSearchSchema,
  component: Component,
})

function Component() {
  return <CustomAnalytics />
}
