import { useQuery, useSuspenseInfiniteQuery, useSuspenseQuery as useTanstackSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { getEventQueryOptions, listEventsInfiniteQueryOptions } from '~/queries/events'
import { Badge, Box, Code, Flex, Grid, Separator, Text, Tooltip } from '@radix-ui/themes'
import { HoverCard, Icon, Spinner } from '~/elementsv2'
import {
  ComponentProps,
  forwardRef,
  Fragment,
  memo,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
  FC,
} from 'react'
import {
  HiArrowPath,
  HiArrowUpTray,
  HiCubeTransparent,
  HiOutlineBolt,
  HiOutlineChatBubbleLeftEllipsis,
  HiOutlineCircleStack,
  HiOutlineSignal,
} from 'react-icons/hi2'
import { type Event } from 'botpress-client'
import { match } from 'ts-pattern'
import { Color } from '~/types'
import { botQueryOptions, listWorkspaceIntegrationsQueryOptions } from '~/queries'
import { cn } from '~/utils'
import { DateTime } from 'luxon'
import { Boundary, type FilterMenuInput, QueryParamBuilder, Page } from '~/componentsV2'
import { z } from 'zod'
import { useIntegrations } from '~/hooks/integrations/useIntegrations'
import { useInfiniteScroll, useQueryKeys } from '~/hooks'
import { JSONTree } from '~/elementsv2/JSONTree'
import { statusDetails, statuses } from '~/features/events/constants'
import { useSuspenseQuery } from '~/services'
import { Card, Button } from '@bpinternal/ui-kit'

type EventListHandle = {
  refetch: () => void
}

const baseEventTypes = ['state_expired', 'message_created', 'task_update'] as const

const eventsSearchSchema = z.object({
  conversationId: z.string().optional(),
  messageId: z.string().optional(),
  type: z.string().optional(),
  userId: z.string().optional(),
  eventId: z.string().optional(),
  statuses: z
    .enum(statuses)
    .optional()
    .catch(() => undefined),
})

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/events')({
  validateSearch: eventsSearchSchema,
  component: Component,
})

function Component() {
  return (
    <Boundary height={'200px'} loaderSize="6">
      <EventsPage />
    </Boundary>
  )
}

const EventsPage = () => {
  const { workspaceId, botId } = Route.useParams()
  const { queryClient } = Route.useRouteContext()

  const [lastFetch, setLastFetch] = useState(0)
  const [isFetching, setIsFetching] = useState(false)
  const eventsListRef = useRef<EventListHandle>(null)

  const bot = useTanstackSuspenseQuery(botQueryOptions({ workspaceId, botId })).data
  const botIntegrations = useIntegrations({
    workspaceId,
    integrationIds: Object.values(bot.integrations).map((i) => i.id),
  })

  const botEvents = Object.keys(bot.events)
  const botIntegrationEvents = botIntegrations.flatMap((integration) =>
    Object.keys(integration.events).map((e) => `${integration.name}:${e}`)
  )

  const filters: FilterMenuInput[] = [
    { name: 'Event', paramName: 'eventId' },
    { name: 'Conversation', paramName: 'conversationId' },
    { name: 'User', paramName: 'userId' },
    { name: 'Message', paramName: 'messageId' },
    {
      name: 'Event Type',
      value: [...baseEventTypes, ...botEvents, ...botIntegrationEvents].map((type) => ({
        name: type,
        paramName: 'type',
        value: type,
        icon: <EventIcon type={type} variant="soft" size="2" />,
      })),
    },
    {
      name: 'Status',
      value: statuses.map((status) => ({ name: statusDetails[status].label, value: status, paramName: 'status' })),
    },
  ]

  return (
    <Page
      title="Events"
      justify="start"
      actions={
        <Button
          variant="ghost"
          color="gray"
          onClick={() => {
            queryClient.invalidateQueries({ queryKey: useQueryKeys().listEvents({ workspaceId, botId }) })
            eventsListRef.current?.refetch()
          }}
          leading={<Icon className={cn({ 'animate-spin': isFetching })} icon={HiArrowPath} color="gray" />}
        >
          <Text size={'1'} color="gray">
            {match({ isFetching, lastFetch })
              .with({ isFetching: true }, () => 'Fetching events...')
              .when(
                ({ lastFetch }) => DateTime.fromMillis(lastFetch).diffNow().milliseconds < -60000,
                () => DateTime.fromMillis(lastFetch).toRelative()
              )
              .otherwise(() => 'A few moments ago')}
          </Text>
        </Button>
      }
    >
      <Flex direction={'column'} gap={'4'}>
        <QueryParamBuilder filters={filters} />
        <Boundary loaderSize={'6'}>
          <EventsList ref={eventsListRef} setLastFetch={setLastFetch} setIsFetching={setIsFetching} />
        </Boundary>
      </Flex>
    </Page>
  )
}

const EventsList = memo(
  forwardRef<EventListHandle, { setLastFetch: (time: number) => void; setIsFetching: (isFetching: boolean) => void }>(
    ({ setLastFetch, setIsFetching }, ref) => {
      const { workspaceId, botId } = Route.useParams()
      const { eventId, ...queryParams } = Route.useSearch()
      const {
        data: fetchedEvents,
        fetchNextPage,
        refetch,
        hasNextPage,
        dataUpdatedAt,
        isFetching,
      } = useSuspenseInfiniteQuery(
        listEventsInfiniteQueryOptions({
          workspaceId,
          botId,
          ...queryParams,
        })
      )

      const bottomRef = useInfiniteScroll(fetchNextPage, hasNextPage)

      const { data: fetchedEvent } = useQuery({
        ...getEventQueryOptions({ workspaceId, botId, eventId: eventId ?? '' }),
        enabled: !!eventId,
      })
      const events = useMemo(
        () => (fetchedEvent?.event ? [fetchedEvent.event] : fetchedEvents.pages.flatMap((p) => p.events)),
        [fetchedEvents.pages, fetchedEvent]
      )

      useImperativeHandle(ref, () => ({
        refetch,
      }))

      useEffect(() => {
        if (dataUpdatedAt) {
          setLastFetch(dataUpdatedAt)
        }
      }, [dataUpdatedAt])

      useEffect(() => {
        setIsFetching(isFetching)
      }, [isFetching])

      return (
        <>
          <Card className="p-0">
            <Text asChild size={'2'}>
              <Grid gapX={'7'} className="relative grid-cols-[repeat(3,auto),1fr,auto,1fr]">
                <Grid align={'center'} p={'2'} className="col-[1/-1] grid-cols-subgrid bg-gray-2 font-medium">
                  <div />
                  <Text>Event</Text>
                  <Text>Time</Text>
                  <Text>Event Identifier</Text>
                  <Text align={'center'}>Status</Text>
                  <Text>Failure Reason</Text>
                </Grid>
                {events.map((event) => (
                  <Fragment key={event.id}>
                    <Separator size={'4'} className="col-[1/-1]" />
                    <EventRow event={event} />
                  </Fragment>
                ))}
              </Grid>
            </Text>
          </Card>
          <div ref={bottomRef} />
          {isFetching && (
            <Flex className="w-full items-center justify-center">
              <Spinner size="6" />
            </Flex>
          )}
        </>
      )
    }
  )
)

const EventRow = ({ event }: { event: Event }) => {
  const { createdAt, failureReason, id, status, type } = event
  const [showJSON, setShowJSON] = useState(false)

  return (
    <>
      <Text asChild color="gray">
        <Grid
          p={'2'}
          align={'center'}
          className="col-[1/-1] grid-cols-subgrid hover:cursor-pointer hover:bg-gray-2"
          onClick={() => {
            setShowJSON((prev) => !prev)
          }}
        >
          <EventIcon type={type} variant="soft" size="3" className="-mr-8" />
          <Text highContrast>{type}</Text>
          <Text>{DateTime.fromISO(createdAt).toRelative()}</Text>
          <Code truncate variant="ghost">
            {id}
          </Code>
          <Tooltip content={statusDetails[status].description} side="top">
            <Badge color={statusDetails[status].color} variant="surface" className="w-fit place-self-center">
              {statusDetails[status].label}
            </Badge>
          </Tooltip>
          <HoverCard
            openDelay={400}
            closeDelay={200}
            onClick={(e) => {
              e.stopPropagation()
            }}
            size={'1'}
            maxWidth={'400px'}
            content={
              <Flex direction={'column'} gap={'3'}>
                <Flex gap={'2'}>
                  <Text size={'2'}>{type}</Text>
                  <Badge color={statusDetails[status].color} variant="surface" className="w-fit">
                    {statusDetails[status].label}
                  </Badge>
                </Flex>
                <Code size={'1'} color="gray">
                  {failureReason}
                </Code>
              </Flex>
            }
            side="top"
          >
            <Code size={'1'} truncate variant="ghost">
              {failureReason}
            </Code>
          </HoverCard>
        </Grid>
      </Text>
      {showJSON ? (
        <>
          <Separator size={'4'} className="col-[1/-1]" />
          <Box p={'4'} className="col-[1/-1]">
            <JSONTree
              hideRoot
              data={
                event.type === 'state_expired'
                  ? {
                      ...event,
                      payload: {
                        ...event.payload,
                        state: { ...event.payload.state, payload: JSON.parse(event.payload?.state?.payload) },
                      },
                    }
                  : event
              }
            />
          </Box>
        </>
      ) : null}
    </>
  )
}

const EventIcon = ({ type, ...iconProps }: { type: string } & Omit<ComponentProps<typeof Icon>, 'icon'>) => {
  const { workspaceId } = Route.useParams()
  const integrations = useSuspenseQuery('integrations_').data
  const workspaceIntegrations = useTanstackSuspenseQuery(listWorkspaceIntegrationsQueryOptions({ workspaceId })).data
  const integrationIcons = [...integrations, ...workspaceIntegrations].reduce(
    (acc, integration) => {
      acc[integration.name] = integration.iconUrl
      return acc
    },
    {} as Record<string, string>
  )

  const [EventIcon, color]: [FC<{ className?: string }>, Color] = match(type)
    .with('state_expired', () => [HiOutlineCircleStack, 'red'] as [FC<{ className?: string }>, Color])
    .with('message_created', () => [HiOutlineChatBubbleLeftEllipsis, 'blue'] as [FC<{ className?: string }>, Color])
    .with('task_update', () => [HiOutlineSignal, 'blue'] as [FC<{ className?: string }>, Color])
    .with('botready', () => [HiOutlineBolt, 'grass'] as [FC<{ className?: string }>, Color])
    .with('botpublished', () => [HiArrowUpTray, 'grass'] as [FC<{ className?: string }>, Color])
    .when(
      (type) => type.split(':').length === 2,
      () => {
        const integrationIconLink = integrationIcons[type.split(':')[0] ?? '']
        const Icon = integrationIconLink
          ? ({ className, ...props }: { className: string }) => (
              <img src={integrationIconLink} {...props} className={cn('p-0.5', className)} aria-label="Event Icon" />
            )
          : HiCubeTransparent
        return [Icon, 'gray'] as [FC<{ className?: string }>, Color]
      }
    )
    .otherwise(() => [HiOutlineSignal, 'grass'] as [FC<{ className?: string }>, Color])

  return <Icon icon={EventIcon} color={color} {...iconProps} />
}
