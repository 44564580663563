import { Skeleton } from '@radix-ui/themes'
import type { SkeletonCell } from '../../types'
import type { RenderCellProps } from 'react-data-grid'
import { skeleton } from './Skeleton.module.css'
import clsx from 'clsx'

type Props = SkeletonCell & { className?: string } & RenderCellProps<unknown>

export const SkeletonCellRenderer = ({ className, computed }: Props) => {
  return (
    <div className={clsx(className, { 'computed-cell': computed })}>
      <Skeleton height={'8px'} className={clsx(className, skeleton)} />
    </div>
  )
}
