import { useMutation } from '@tanstack/react-query'
import { BillingApiClient } from '../../features/billing/services'
import { showErrorToast } from '@bpinternal/ui-kit'

export const useCustomerPortal = (workspaceId: string) => {
  return useMutation({
    gcTime: 0,
    mutationFn: async () => {
      return BillingApiClient.generateCustomerPortalSession(workspaceId)
    },
    onSuccess: (data) => {
      const height = 900
      const width = 1200
      const left = (window.outerWidth - width) / 2
      const top = (window.outerHeight - height) / 2
      window.open(data.url, undefined, `popup,width=${width},height=${height},left=${left},top=${top}`)
    },
    onError: () => {
      showErrorToast('There was an error while opening the billing portal. Please try again.')
    },
    meta: {
      suppressError: true,
    },
  })
}
