import { TextField } from '@radix-ui/themes'
import { ComponentProps, forwardRef, ReactNode } from 'react'
import { cn } from '../utils'

type Props = { leading?: ReactNode; trailing?: ReactNode } & ComponentProps<typeof TextField.Root> &
  Pick<ComponentProps<typeof TextField.Slot>, 'gap'>

export const Input = forwardRef<HTMLInputElement, Props>(({ leading, trailing, className, ...props }, ref) => {
  return (
    <TextField.Root ref={ref} {...props} className={cn('w-full', className)}>
      {leading && <TextField.Slot side="left">{leading}</TextField.Slot>}
      {trailing && <TextField.Slot side="right">{trailing}</TextField.Slot>}
    </TextField.Root>
  )
})

Input.displayName = 'Input'
