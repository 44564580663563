import React, { ButtonHTMLAttributes, forwardRef } from 'react'

import cn from 'classnames'

import { Tooltip as RadixTooltip } from '@radix-ui/themes'
import { isMacOS } from '~/utils'
import { Button } from '@bpinternal/ui-kit'

const ShortcutKey = ({ children }: { children: string }): JSX.Element => {
  const isMac = isMacOS()
  const className =
    'inline-flex items-center justify-center w-5 h-5 p-1 text-[0.625rem] rounded font-semibold leading-none border border-gray-6 text-gray-11 border-b-2 bg-gray-1'

  if (children === 'Mod') {
    return <kbd className={className}>{isMac ? '⌘' : 'Ctrl'}</kbd> // ⌃
  }

  if (children === 'Shift') {
    return <kbd className={className}>⇧</kbd>
  }

  if (children === 'Alt') {
    return <kbd className={className}>{isMac ? '⌥' : 'Alt'}</kbd>
  }

  return <kbd className={className}>{children}</kbd>
}

type TooltipProps = {
  children: React.ReactNode
  title: string
  shortcut?: string[]
}

const ShortcutTooltip = ({ children, title, shortcut }: TooltipProps): JSX.Element => {
  const content = shortcut ? (
    <>
      <span className="flex items-center gap-0.5">
        {shortcut.map((shortcutKey) => (
          <ShortcutKey key={shortcutKey}>{shortcutKey}</ShortcutKey>
        ))}
        <span className="text-gray-11">{title}</span>
      </span>
    </>
  ) : (
    <span className="text-gray-11">{title}</span>
  )

  return (
    <RadixTooltip className="mb-1 border-gray-4 bg-gray-3 [&_.rt-TooltipArrow]:!hidden" content={content}>
      {children}
    </RadixTooltip>
  )
}

type ToolbarButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  active?: boolean
  tooltip?: string
  tooltipShortcut?: string[]
}

const ToolbarButton = forwardRef<HTMLButtonElement, ToolbarButtonProps>(
  ({ children, className, tooltip, tooltipShortcut, active, ...rest }, ref) => {
    const buttonClass = cn('gap-1 min-w-[2rem] px-2 w-auto', {
      'bg-gray-2': active,
      [className ?? '']: !!className,
    })

    const content = (
      // @ts-ignore
      <Button className={buttonClass} variant="minimal" size="2" ref={ref} color="gray" {...rest}>
        {children}
      </Button>
    )

    if (tooltip) {
      return (
        <ShortcutTooltip title={tooltip} shortcut={tooltipShortcut}>
          {content}
        </ShortcutTooltip>
      )
    }

    return content
  }
)

export { ToolbarButton }
