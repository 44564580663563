import { Link } from '@radix-ui/themes'
import type { UrlCell } from '../../types'
import type { RenderCellProps } from 'react-data-grid'
import { link } from './Link.module.css'
import clsx from 'clsx'

type Props = UrlCell & { className?: string } & RenderCellProps<unknown>

export const LinkCellRenderer = ({ className, url, label, computed }: Props) => {
  return (
    <Link className={clsx(className, link, { 'computed-cell': computed })} href={url} target="_blank" rel="noreferrer">
      {label ?? url}
    </Link>
  )
}
