import { useMutation, useQueryClient } from '@tanstack/react-query'
import { getApiClient, getGenericClient } from '../../client'
import { getQueryKey } from '../../services'
import { showErrorToast } from '@bpinternal/ui-kit'
import { handleHttpErrors } from '../../shared'

type UpdateWorkspaceParams = { workspaceId: string; onSuccess?: () => Promise<void> } & Parameters<
  ReturnType<typeof getGenericClient>['updateWorkspaceMember']
>['0']

export function useUpdateWorkspaceMember() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ workspaceId, id, ...props }: UpdateWorkspaceParams) => {
      return await getApiClient(workspaceId)
        .updateWorkspaceMember({ id, ...props })
        .catch(handleHttpErrors)
    },
    onSuccess: async (_, { onSuccess, workspaceId }) => {
      const queryKey = getQueryKey('workspaces_/$workspaceId_/members', { workspaceId })
      await queryClient.invalidateQueries({ queryKey })

      await onSuccess?.()
    },
    onError: (error: Error) => {
      showErrorToast(error.message)
    },
  })
}
