import { Calendar, Checkbox } from '@bpinternal/ui-kit'
import { Flex, Tooltip } from '@radix-ui/themes'
import { TriangleAlert } from 'lucide-react'
import { DateTime } from 'luxon'
import { useState } from 'react'
import type { DateRange } from 'react-day-picker'
import { parseNumber, ValidationResult, ValueEditorProps } from 'react-querybuilder'
import { Icon, Input, Popover, Select } from '~/elementsv2'

export const CustomValueEditor = (props: ValueEditorProps) => {
  const isBetween = props.operator === 'between' || props.operator === 'notBetween'
  const isIn = props.operator === 'in' || props.operator === 'notIn' || props.inputType === 'array'
  const isNull = props.operator === 'null' || props.operator === 'notNull'
  const validation = props.validation as ValidationResult | undefined
  const error = !validation?.valid ? validation?.reasons?.join(', ') : undefined

  const wrapWithError = (input: JSX.Element) => (
    <div className={'errorWrapper'}>
      {input}
      {error && (
        <Tooltip content={error}>
          <Icon variant="soft" icon={TriangleAlert} color={'red'} />
        </Tooltip>
      )}
    </div>
  )

  if (isNull) {
    return null
  }

  if (props.values?.length) {
    return (
      <Select
        size={'2'}
        value={props.value}
        onValueChange={props.handleOnChange}
        items={props.values.map((option) => ({
          type: 'item',
          value: option.name,
          content: option.label,
        }))}
      />
    )
  }

  if (isIn) {
    return wrapWithError(
      <Tooltip content="Separate values with a comma">
        <Input value={props.value} onChange={(e) => props.handleOnChange(e.target.value)} size={'2'} />
      </Tooltip>
    )
  }

  if (props.inputType === 'string') {
    return wrapWithError(
      <Input
        className={'max-w-36'}
        value={props.value}
        onChange={(e) => props.handleOnChange(e.target.value)}
        size={'2'}
      />
    )
  } else if (props.inputType === 'number') {
    return wrapWithError(
      <Input
        className={'max-w-36'}
        value={props.value}
        onChange={(e) => props.handleOnChange(parseNumber(e.target.value, { parseNumbers: true }))}
        size={'2'}
        type={'number'}
      />
    )
  } else if (props.inputType === 'boolean') {
    return wrapWithError(
      <Checkbox
        className={'max-w-36'}
        checked={props.value}
        onCheckedChange={(checked) => props.handleOnChange(checked)}
      />
    )
  } else if (props.inputType === 'date') {
    if (isBetween) {
      return wrapWithError(
        <DateRangePicker value={props.value} handleOnChange={(value) => props.handleOnChange(value)} />
      )
    } else {
      return wrapWithError(<DatePicker value={props.value} handleOnChange={props.handleOnChange} />)
    }
  }
}

type DatePickerProps = {
  value: unknown
  handleOnChange: (value: string) => void
}

// TODO: Create a proper date picker component these pickers are hacky to save time for now
const DatePicker = ({ value, handleOnChange }: DatePickerProps) => {
  const stringValue = typeof value === 'string' || value instanceof String ? (value as string) : ''

  const luxonDate = DateTime.fromFormat(stringValue, 'yyyy-MM-dd')
  const jsDate = luxonDate.isValid ? luxonDate.toJSDate() : undefined
  const [calendarOpened, setCalendarOpened] = useState(false)

  const handleDateChange = (date?: Date) => {
    if (!date) {
      return
    }
    handleOnChange(DateTime.fromJSDate(date).toFormat('yyyy-MM-dd'))
  }

  return (
    <Flex>
      <Input
        onFocus={(e) => {
          e.stopPropagation()
          setCalendarOpened(true)
          return
        }}
        placeholder="YYYY-MM-DD"
        className={'max-w-36'}
        value={stringValue}
        onChange={(e) => handleOnChange(e.target.value)}
        size={'2'}
        type={'date'}
      />
      <Popover
        open={calendarOpened}
        onFocusOutside={() => setCalendarOpened(false)}
        onOpenAutoFocus={(e) => e.preventDefault()}
        side="right"
        trigger={<div />}
      >
        <Calendar
          month={jsDate}
          enableYearNavigation
          onMonthChange={handleDateChange}
          selected={luxonDate.toJSDate()}
          onSelect={(date) => {
            handleDateChange(date)
            setCalendarOpened(false)
          }}
        />
      </Popover>
    </Flex>
  )
}

type DateRangePickerProps = {
  value: [string, string]
  handleOnChange: (range: [string, string]) => void
}

// TODO: Create a proper date picker component these pickers are hacky to save time for now
const DateRangePicker = ({ value, handleOnChange }: DateRangePickerProps) => {
  const [startDate, endDate] = value
  const luxonDates = value?.map?.((date) => DateTime.fromFormat(date ?? '', 'yyyy-MM-dd')) ?? []
  const jsDates = luxonDates.map((luxonDate) => (luxonDate.isValid ? luxonDate.toJSDate() : undefined))

  const [calendarOpened, setCalendarOpened] = useState(false)

  const handleDateRangeChange = (dateRange?: DateRange) => {
    const from = dateRange?.from ? DateTime.fromJSDate(dateRange.from).toFormat('yyyy-MM-dd') : startDate
    const to = dateRange?.to ? DateTime.fromJSDate(dateRange.to).toFormat('yyyy-MM-dd') : endDate
    handleOnChange([from, to])
  }

  return (
    <Flex>
      <Input
        onFocus={(e) => {
          e.stopPropagation()
          setCalendarOpened(true)
          return
        }}
        placeholder="YYYY-MM-DD"
        className={'max-w-36'}
        value={startDate}
        onChange={(e) => handleOnChange([e.target.value, endDate])}
        size={'2'}
        type={'date'}
      />
      <Input
        onFocus={(e) => {
          e.stopPropagation()
          setCalendarOpened(true)
          return
        }}
        placeholder="YYYY-MM-DD"
        className={'max-w-36'}
        value={endDate}
        onChange={(e) => handleOnChange([startDate, e.target.value])}
        size={'2'}
        type={'date'}
      />
      <Popover
        open={calendarOpened}
        onFocusOutside={() => setCalendarOpened(false)}
        onOpenAutoFocus={(e) => e.preventDefault()}
        side="right"
        trigger={<div />}
      >
        <Calendar
          mode="range"
          enableYearNavigation
          selected={{ from: jsDates[0], to: jsDates[1] }}
          onSelect={handleDateRangeChange}
        />
      </Popover>
    </Flex>
  )
}
