import { Theme } from '@radix-ui/themes'
import { type ComponentProps } from 'react'
import './index.css'

type Props = ComponentProps<typeof Theme>

export const ThemeColor = 'blue' as const
export const ThemeProvider = (props: Props) => {
  return <Theme {...props} accentColor={ThemeColor} style={{ minHeight: 'auto' }} />
}
