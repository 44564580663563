import { IconButton, showPromptDialog } from '@bpinternal/ui-kit'
import { Flex, Text, Tooltip } from '@radix-ui/themes'
import { useEffect, useRef, useState } from 'react'
import { HiMiniArrowPath } from 'react-icons/hi2'
import { Input } from '~/elementsv2'
import { generateRandomName } from '~/features/bots/utils/generateBotName'

export const createBotPrompt = () =>
  showPromptDialog<string>(
    ({ setValue, setValid }) => {
      const ref = useRef<HTMLInputElement>(null)
      const [innerValue, setInnerValue] = useState(generateRandomName())

      useEffect(() => {
        ref.current?.select()
        setValid(true)
      }, [])

      useEffect(() => {
        setValue(innerValue)
      }, [innerValue])

      return (
        <Flex direction={'column'} gap={'2'}>
          <Text size={'1'}>Choose a name for your new bot, this can be changed later</Text>
          <Input
            ref={ref}
            placeholder="Bot name"
            onFocus={(e) => e.target.select()}
            trailing={
              <Tooltip content="Generate a random name">
                <IconButton
                  onClick={() => {
                    setInnerValue(generateRandomName())
                    setValid(true)
                  }}
                  size={'1'}
                  variant="minimal"
                  icon={HiMiniArrowPath}
                />
              </Tooltip>
            }
            value={innerValue}
            onChange={(e) => {
              setInnerValue(e.target.value)
              setValid(e.target.value.length > 0)
            }}
          />
        </Flex>
      )
    },
    {
      title: 'Create Bot',
      confirmLabel: 'Create',
      showCancel: true,
      cancelLabel: 'Cancel',
    }
  )
