import { forwardRef } from 'react'
import { Checkbox as RadixCheckbox } from '@radix-ui/themes'

type Props = {
  className?: string
  children?: React.ReactNode
} & React.ComponentProps<typeof RadixCheckbox>

export const Checkbox = forwardRef<HTMLButtonElement, Props>((props, ref) => <RadixCheckbox ref={ref} {...props} />)

Checkbox.displayName = 'Checkbox'
