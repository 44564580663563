import { Button } from '@bpinternal/ui-kit'
import { Badge, Box, Flex, Separator, Text } from '@radix-ui/themes'
import { useParams } from '@tanstack/react-router'
import {
  Boxes,
  ChartPie,
  ChartSpline,
  ChevronDown,
  FileText,
  House,
  LayoutPanelLeft,
  MessageSquare,
  MessageSquareReply,
  MessagesSquare,
  Radio,
  Settings,
  SquareTerminal,
  Table,
  TriangleAlert,
  UsersRound,
  Variable,
} from 'lucide-react'
import { type NavbarItem, Navbar, Boundary, BotSelectorMenu, BotIcon } from '~/componentsV2'
import { Icon } from '~/elementsv2'
import { createBotPrompt } from '~/features/bots/services/createBotPrompt'
import { NotificationBadge } from '~/features/issues/componentsV2'
import { useCreateBot, useCurrentRouteId } from '~/hooks'
import { useQuery } from '~/services'
import { useLocalStore } from '~/stores'
import { cn } from '~/utils'

type Props = { className?: string }

export const SideNav = ({ className, ...props }: Props) => {
  const { workspaceId, botId } = useParams({ strict: false })

  const routeId = useCurrentRouteId()

  const workspaceNavbarItems: NavbarItem[] = [
    { text: 'Home', leadingIcon: House, to: '/workspaces/$workspaceId/home' },
    { text: 'Your Integrations', leadingIcon: Boxes, to: '/workspaces/$workspaceId/integrations' },
    { text: 'Usage', leadingIcon: ChartPie, to: '/workspaces/$workspaceId/usage' },
    { text: 'Settings', leadingIcon: Settings, to: '/workspaces/$workspaceId/settings' },
  ].map((item) => {
    return { ...item, active: routeId?.includes(item.to) } as NavbarItem
  })

  const botNavbarItems: NavbarItem[] = [
    { text: 'Overview', leadingIcon: LayoutPanelLeft, to: '/workspaces/$workspaceId/bots/$botId/overview' },
    {
      text: 'Webchat',
      leadingIcon: MessageSquare,
      to: '/workspaces/$workspaceId/bots/$botId/webchat',
    },
    { text: 'Logs', leadingIcon: SquareTerminal, to: '/workspaces/$workspaceId/bots/$botId/logs' },
    {
      text: 'Conversations',
      leadingIcon: MessagesSquare,
      to: '/workspaces/$workspaceId/bots/$botId/conversations',
    },
    {
      text: 'Users',
      leadingIcon: UsersRound,
      to: '/workspaces/$workspaceId/bots/$botId/users',
    },
    {
      text: 'Files',
      leadingIcon: FileText,
      to: '/workspaces/$workspaceId/bots/$botId/files',
    },
    { text: 'Analytics', leadingIcon: ChartSpline, to: '/workspaces/$workspaceId/bots/$botId/analytics' },
    { text: 'Events', leadingIcon: Radio, to: '/workspaces/$workspaceId/bots/$botId/events' },
    {
      text: 'Tables',
      leadingIcon: Table,
      to: '/workspaces/$workspaceId/bots/$botId/tables',
      trailingItem: (
        <Badge color="green" variant="outline">
          New
        </Badge>
      ),
    },
    {
      text: 'Issues',
      leadingIcon: TriangleAlert,
      trailingItem: (
        <Boundary loader={null} onError={() => null}>
          <NotificationBadge />
        </Boundary>
      ),
      to: '/workspaces/$workspaceId/bots/$botId/issues',
    },
    {
      text: 'Configuration variables',
      leadingIcon: Variable,
      to: '/workspaces/$workspaceId/bots/$botId/configuration-variables',
    },
    {
      text: 'Human in the loop',
      leadingIcon: MessageSquareReply,
      to: '/workspaces/$workspaceId/bots/$botId/hitl',
    },
  ].map((item) => {
    return { ...item, active: routeId?.includes(item.to) } as NavbarItem
  })

  const lastBotId = useLocalStore((state) => state.getLastActiveBotId(workspaceId))
  const navBotId = botId ?? lastBotId
  const { data: bots } = useQuery('workspaces_/$workspaceId_/bots_', { workspaceId: workspaceId ?? '' })
  const { mutate: createBot, isPending } = useCreateBot(workspaceId ?? '')

  const { data: bot } = useQuery('workspaces_/$workspaceId_/bots_/$botId_', {
    workspaceId: workspaceId ?? '',
    botId: navBotId ?? '',
  })

  const hasBots = (bots?.length ?? 0) > 0

  return (
    <Flex direction={'column'} p={'2'} pt={'0'} {...props} className={cn(className)}>
      {workspaceId && (
        <>
          <Navbar workspaceId={workspaceId} items={workspaceNavbarItems} direction="column" />
          <Box p={'3'}>
            <Separator orientation={'horizontal'} size={'4'} />
          </Box>
          {hasBots ? (
            <BotSelectorMenu bots={bots ?? []}>
              <Flex
                gap={'2'}
                px={'3'}
                py={'2'}
                my={'-1'}
                className={cn(hasBots ? 'rounded hover:bg-gray-3' : 'cursor-not-allowed opacity-70')}
              >
                {bot?.id && <BotIcon id={bot.id} size="1" />}
                <Text color="gray" size={'1'}>
                  {bot?.name ? bot.name : hasBots ? 'Select a bot...' : 'No bots in workspace'}
                </Text>
                {hasBots && <Icon className="ml-auto" color="gray" icon={ChevronDown} />}
              </Flex>
            </BotSelectorMenu>
          ) : (
            <Button variant="dashed" onClick={() => void createBotPrompt().then(createBot)} loading={isPending}>
              Create Bot
            </Button>
          )}
          {!!bot && <Navbar workspaceId={workspaceId} botId={navBotId} items={botNavbarItems} direction="column" />}
        </>
      )}
    </Flex>
  )
}
