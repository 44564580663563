import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { getApiClient, getApiClientForBot } from '~/client'
import { showErrorToast } from '@bpinternal/ui-kit'

type UpsetFileParams = Omit<Parameters<ReturnType<typeof getApiClient>['upsertFile']>[0], 'size'>

export function useUploadFile({ botId, workspaceId }: { workspaceId: string; botId: string }) {
  return useMutation({
    onError: () => {
      showErrorToast('Unable to upload the file')
    },
    mutationFn: async ({
      key,
      index,
      tags,
      contentType,
      accessPolicies,
      content,
      url,
      expiresAt,
    }: UpsetFileParams & { url?: string; content?: unknown }) => {
      if (url && content) {
        throw 'Cannot provide both content and URL, please provide only one of them'
      }

      if (url) {
        content = await axios
          .get(url, { responseType: 'arraybuffer' })
          .then((res) => res.data)
          .catch((err) => {
            throw `Failed to download file from provided URL: ${err.message}`
          })
      }

      if (!content) {
        showErrorToast('No content was provided for the file')
        return
      }

      let buffer: ArrayBuffer | Buffer | Blob
      let size: number

      if (typeof content === 'string') {
        const encoder = new TextEncoder()
        const uint8Array = encoder.encode(content)
        // Uint8Array is supported by both Node.js and browsers. Buffer.from() is easier but Buffer is only available in Node.js.
        buffer = uint8Array
        size = uint8Array.byteLength
      } else if (content instanceof Uint8Array) {
        // This supports Buffer too as it's a subclass of Uint8Array
        buffer = content
        size = buffer.byteLength
      } else if (content instanceof ArrayBuffer) {
        buffer = content
        size = buffer.byteLength
      } else if (content instanceof Blob) {
        buffer = content
        size = content.size
      } else {
        showErrorToast('The provided content is not supported')
        return
      }

      const { file } = await getApiClientForBot({ botId, workspaceId }).upsertFile({
        key,
        tags,
        index,
        accessPolicies,
        contentType,
        size,
        expiresAt,
        publicContentImmediatelyAccessible: true,
      })

      try {
        await axios.put(file.uploadUrl, buffer, {
          maxBodyLength: Infinity,
          headers: {
            'Content-Type': file.contentType,
            'x-amz-tagging': 'public=true',
          },
        })
        // If we get the file too quickly, it might not be available yet
        for (let i = 0; i < 9; i++) {
          try {
            await axios.get(file.url)
            return { file }
          } catch (err) {
            await new Promise((resolve) => setTimeout(resolve, 250 * i ** 2))
          }
        }
      } catch (err: any) {
        throw `Failed to upload file: ${err.message}`
      }
      return { file }
    },
  })
}
