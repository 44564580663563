import { useMemo } from 'react'
import { useCustomerPortal } from '~/hooks'
import { HiOutlineExclamationCircle } from 'react-icons/hi2'
import { Link } from '@radix-ui/themes'
import { useSuspenseQuery } from '@tanstack/react-query'
import { listInvoicesQueryOptions } from '../../../queries'
import { DateTime } from 'luxon'
import { Callout } from '@bpinternal/ui-kit'

type Props = {
  workspaceId: string
}

export const UnpaidInvoiceCallout = ({ workspaceId }: Props) => {
  const { invoices } = useSuspenseQuery(listInvoicesQueryOptions(workspaceId)).data
  const unpaidInvoices = useMemo(
    () =>
      invoices?.filter(
        (invoice) =>
          invoice.paymentStatus === 'open' && invoice.dueDate && DateTime.fromISO(invoice.dueDate) < DateTime.now()
      ),
    [invoices]
  )

  const { mutate: openCustomerPortal } = useCustomerPortal(workspaceId)

  return unpaidInvoices.length > 0 ? (
    <Callout color="red" icon={HiOutlineExclamationCircle} size={'1'}>
      Your workspace has an unpaid invoice. Please
      <Link href="#" color="red" weight={'medium'} onClick={() => openCustomerPortal()}>
        {' '}
        pay your invoice
      </Link>{' '}
      to restore full functionality.
    </Callout>
  ) : null
}
