import { Badge, Checkbox, Flex, Grid, Separator, Text } from '@radix-ui/themes'
import { createFileRoute } from '@tanstack/react-router'
import { IssueCategory } from '~/features/issues/'
import { EmptyState, Link, Tag } from '~/elementsv2'
import { useSuspenseQuery } from '@tanstack/react-query'
import { listAllIssuesQueryOptions } from '~/queries'
import { Fragment, useState } from 'react'

import EmptyStateIcon from '~/assets/programming-23.svg?react'

import type { Issue } from 'botpress-client'
import { IdentifierDropdownMenu, Page } from '~/componentsV2'
import { createIdentifierStudioUrl } from '~/utils'
import { match } from 'ts-pattern'
import { useResolveIssues } from '~/hooks/issues/useResolveIssues'
import { orderBy } from 'lodash'
import { DateTime } from 'luxon'
import { Card, Button, showConfirmationPrompt } from '@bpinternal/ui-kit'

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/issues/')({
  component: () => {
    return (
      <Page title="Issues">
        <IssuesList />
      </Page>
    )
  },
})

export const IssuesList = () => {
  const { botId, workspaceId } = Route.useParams()
  const [selectedIssues, setSelectedIssues] = useState<string[]>([])

  const { mutate: resolveIssues } = useResolveIssues({ botId, workspaceId })
  const issues = useSuspenseQuery(listAllIssuesQueryOptions({ botId, workspaceId })).data

  return (
    <Card className="p-0">
      <Text asChild size={'2'}>
        <Grid gapX={'7'} className="relative grid-cols-[auto,1fr,repeat(2,auto)]">
          <Grid align={'center'} p={'2'} pr={'6'} className="col-[1/-1] grid-cols-subgrid bg-gray-2 font-medium">
            <Checkbox
              checked={selectedIssues.length === issues.length && issues.length > 0}
              onCheckedChange={(checked) => setSelectedIssues(checked ? issues.map((i) => i.id) : [])}
              className="-mr-8"
            />
            <Button
              className="w-fit"
              size={'2'}
              variant="solid"
              disabled={selectedIssues.length === 0}
              onClick={() =>
                showConfirmationPrompt(
                  `Resolving issues will permanently remove them from the list. Are you sure you want to resolve ${selectedIssues.length} issues?`,
                  {
                    title: 'Resolve Issues',
                  }
                ).then(() => resolveIssues({ issueIds: selectedIssues }))
              }
            >
              Resolve Issues
            </Button>
            <Text>Category</Text>
            <Text>Events</Text>
          </Grid>
          {issues.length === 0 ? (
            <>
              <Separator size={'4'} className="col-[1/-1]" />
              <EmptyState
                iconSize={6}
                icon={EmptyStateIcon}
                className="col-[1/-1] py-16"
                title="No issues found!"
                description="Great news! No issues were found in your bot. Issues will appear here when they are detected."
              />
            </>
          ) : (
            orderBy(issues, 'lastSeenAt', 'desc').map((issue) => (
              <Fragment key={issue.id}>
                <Separator size={'4'} className="col-[1/-1]" />
                <IssueRow
                  issue={issue}
                  selected={selectedIssues.includes(issue.id)}
                  selectIssue={(selected) =>
                    setSelectedIssues((prev) => (selected ? [...prev, issue.id] : prev.filter((id) => id !== issue.id)))
                  }
                />
              </Fragment>
            ))
          )}
        </Grid>
      </Text>
    </Card>
  )
}

const IssueRow = ({
  issue,
  selected,
  selectIssue,
}: {
  issue: Issue
  selected: boolean
  selectIssue: (selected: boolean) => void
}) => {
  const { workspaceId, botId } = Route.useParams()
  const { workflowId, nodeId } = issue.groupedData
  const category = IssueCategory[issue.category]

  return (
    <Grid p={'2'} align={'center'} className="col-[1/-1] grid-cols-subgrid py-4">
      <Checkbox className="-mr-8 self-start" checked={selected} onCheckedChange={(checked) => selectIssue(!!checked)} />
      <Flex direction={'column'} gap={'3'}>
        <Flex direction={'column'}>
          <Flex align={'baseline'} gap={'2'}>
            <Link
              to="/workspaces/$workspaceId/bots/$botId/issues/$issueId"
              weight={'medium'}
              params={{ workspaceId, botId, issueId: issue.id }}
            >
              {issue.title}
            </Link>
            <Text className="gap-1" size={'1'} color="gray">
              <Flex gap={'2'}>
                <Text>{DateTime.fromISO(issue.lastSeenAt).toRelative()}</Text>
                <Text>|</Text>
                <Text>{DateTime.fromISO(issue.createdAt).toRelative()?.replace('ago', 'old')}</Text>
              </Flex>
            </Text>
          </Flex>
          <Text color="gray" className="line-clamp-3">
            {issue.description}
          </Text>
        </Flex>
        <Flex wrap={'wrap'} gap={'2'}>
          {Object.entries(issue.groupedData).map(([key, { raw, pretty }]) => {
            const studioUrl = match({ key })
              .with({ key: 'workflowId' }, () => {
                return createIdentifierStudioUrl({ botId, workflowId: raw })
              })
              .with({ key: 'nodeId' }, () => {
                return createIdentifierStudioUrl({ botId, workflowId: workflowId?.raw, nodeId: raw })
              })
              .with({ key: 'cardId' }, () => {
                return createIdentifierStudioUrl({
                  botId,
                  workflowId: workflowId?.raw,
                  nodeId: nodeId?.raw,
                  cardId: raw,
                })
              })
              .otherwise(() => undefined)

            return (
              <IdentifierDropdownMenu key={key} studioUrl={studioUrl} value={raw}>
                <Tag clickable label={key} value={pretty ?? raw} />
              </IdentifierDropdownMenu>
            )
          })}
        </Flex>
      </Flex>
      <Badge color={category.color} className="w-fit justify-self-center">
        {category.title}
      </Badge>
      <Text className="justify-self-center">{issue.eventsCount}</Text>
    </Grid>
  )
}
