import { useUpdateBot } from '../../hooks'
import { showSuccessToast } from '@bpinternal/ui-kit'

type Props = { botId: string; workspaceId: string; alwaysAlive: boolean; botName?: string }

export function useUpdateAlwaysAlive() {
  const { mutate: updateBot, ...rest } = useUpdateBot()

  const updateAlwaysAlive = ({ botId, alwaysAlive, workspaceId, botName }: Props) => {
    return updateBot({
      id: botId,
      workspaceId,
      alwaysAlive,
      onSuccess: () =>
        showSuccessToast(
          `The always alive mode has been turned ${alwaysAlive ? 'on' : 'off'}${
            botName ? ` for the bot ${botName}` : ''
          }`
        ),
    })
  }

  return { mutate: updateAlwaysAlive, ...rest }
}
