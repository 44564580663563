import 'react-data-grid/lib/styles.css'
import './data-grid.css'
import { type ComponentProps, type FC } from 'react'
import { default as ReactDataGrid, type ColumnOrColumnGroup, type RenderCellProps } from 'react-data-grid'
import { datagrid, hidden, sortableHeaderCellContainer, sortableHeaderCellIcon } from './data-grid.module.css'
import clsx from 'clsx'
import { CellRenderer } from './renderers/CellRenderer'
import { Flex, Text } from '@radix-ui/themes'
import { ChevronDown, ChevronsUpDown, ChevronUp } from 'lucide-react'

type Props<R, SR = unknown, K extends React.Key = React.Key> = {
  className?: string
  children?: React.ReactNode
  renderers: Record<string, { renderCell: FC<RenderCellProps<R, SR>> }>
} & Omit<ComponentProps<typeof ReactDataGrid<R, SR, K>>, 'renderers'>

export const DataGrid = <R, SR = unknown, K extends React.Key = React.Key>({
  className,
  columns,
  renderers,
  ...props
}: Props<R, SR, K>) => {
  const augmentedColumns: readonly ColumnOrColumnGroup<NoInfer<R>, NoInfer<SR>>[] = columns.map((column) => {
    return {
      ...column,
      renderCell: (cellProps) => <CellRenderer {...cellProps} customRenderer={renderers as any} />,
      renderHeaderCell: (props) => {
        const { column, sortDirection } = props
        const Icon = sortableHeaderCellIcons[sortDirection ?? 'NONE']
        return (
          <Flex align={'center'} justify={'between'} className={clsx(column.sortable && sortableHeaderCellContainer)}>
            <Text weight={'bold'} color="gray" highContrast size={'2'}>
              {props.column.name}
            </Text>
            <Icon size={16} className={clsx(sortableHeaderCellIcon, !sortDirection && hidden)} />
          </Flex>
        )
      },
    }
  })

  return (
    <ReactDataGrid<R, SR, K>
      {...props}
      className={clsx(datagrid, 'bp-data-grid', className)}
      columns={augmentedColumns}
    />
  )
}

const sortableHeaderCellIcons = {
  ASC: ChevronDown,
  DESC: ChevronUp,
  NONE: ChevronsUpDown,
}

DataGrid.displayName = 'DataGrid'
