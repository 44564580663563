import { Button, showConfirmationPrompt } from '@bpinternal/ui-kit'
import { useQueryClient } from '@tanstack/react-query'
import { sortBy } from 'lodash'
import { WorkspaceObject } from '~/features/workspaces/types'
import { useCurrentUserWorkspaceMember, useDeleteWorkspaceMember, useWorkspaces } from '~/hooks'
import { useLocalStore } from '~/stores'
import { Flex, Text } from '@radix-ui/themes'

type Props = {
  workspace: WorkspaceObject
  navigateToHome: () => Promise<void>
}

export function LeaveWorkspaceButton({ workspace, navigateToHome }: Props) {
  const queryClient = useQueryClient()
  const selfMember = useCurrentUserWorkspaceMember({ workspaceId: workspace.id })
  const { mutate: deleteWorkspaceMember, isSuccess: leaveWorkspaceSuccess } = useDeleteWorkspaceMember()

  const { data: workspaces } = useWorkspaces()
  const { setLastActiveWorkspaceId } = useLocalStore()

  if (!selfMember) {
    return null
  }

  const leaveWorkspace = () => {
    deleteWorkspaceMember({
      workspaceId: workspace.id,
      id: selfMember.id,
      requesterMemberId: selfMember.id,
      onSuccess: async () => {
        const newestWorkspace = sortBy(
          workspaces?.filter((w) => w.id !== workspace.id) ?? [], // Exclude the workspace that was abandoned, as it will still be in the cache.
          (x) => x.createdAt
        ).pop()
        if (newestWorkspace) {
          setLastActiveWorkspaceId(newestWorkspace.id)
        }

        await navigateToHome() // Important: Wait for route change before invalidating cache otherwise queries for the abandoned workspace will try to be refetched.

        await queryClient.invalidateQueries()
      },
    })

    return leaveWorkspaceSuccess
  }

  return (
    <>
      <Button
        variant="soft"
        color="red"
        disabled={selfMember.role === 'owner'}
        onClick={() =>
          void showConfirmationPrompt(
            <Flex gap={'3'} direction={'column'}>
              <Text>
                You're about to leave the workspace <Text weight={'bold'}>{workspace.name}</Text>. You will need to
                contact the owner or administrator(s) of this workspace if you need to regain access later.
              </Text>
              <Text>Are you sure you want to proceed?</Text>
            </Flex>,
            {
              variant: 'danger',
              title: 'Leave Workspace',
              size: '4',
              confirmLabel: 'Yes, leave',
              cancelLabel: 'No, go back',
            }
          ).then(() => leaveWorkspace())
        }
      >
        Leave Workspace
      </Button>
    </>
  )
}
