import cookie from 'js-cookie'
import { PAT_KEY, config } from '../shared'
import { useState } from 'react'
import { Input } from '~/elementsv2'
import { Flex } from '@radix-ui/themes'
import { Button, Callout } from '@bpinternal/ui-kit'

type Props = {
  children: React.ReactNode
}

const PreviewsDomain = 'botpress.sh'

export const PatProvider = ({ children }: Props) => {
  const [patInput, setPatInput] = useState('')

  const pat = config.pat ?? cookie.get(PAT_KEY)

  if (config.usePat && !pat) {
    return (
      <Callout className="mx-auto mt-24 max-w-md" color="gray">
        <Flex direction={'column'} gap={'4'}>
          <h1 className=" text-base">Please enter your PAT:</h1>
          <Input value={patInput} onChange={(e) => setPatInput(e.target.value)} />
          <Button
            className="self-end"
            onClick={() => {
              cookie.set(PAT_KEY, patInput, {
                domain: location.hostname.endsWith(PreviewsDomain) ? PreviewsDomain : location.hostname,
                expires: 30,
              })
              window.location.reload()
            }}
          >
            Submit
          </Button>
        </Flex>
      </Callout>
    )
  }

  return <>{children}</>
}
