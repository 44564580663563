import { useState } from 'react'
import { Button, Field } from '~/elements'
import cx from 'classnames'
import { Accept, useDropzone } from 'react-dropzone'
import { showErrorToast } from '@bpinternal/ui-kit'
import imageCompression from 'browser-image-compression'

const MAX_UPLOAD_SIZE = 5 * 1024 * 1024 // 5MB
const MAX_COMPRESSED_SIZE = 20 * 1024 // 20KB
const ACCEPTED_FILES: Accept = {
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/svg+xml': ['.svg'],
  'image/png': ['.png'],
  'image/webp': ['.webp'],
}

type Props = {
  pictureUrl?: string
  onChange?: (pictureUrl: string) => void
  children?: React.ReactNode
  className?: string
}

export function ProfilePictureField(props: Props) {
  const [profilePicture, setProfilePicture] = useState(props.pictureUrl)

  const processFile = async (imageFile: File) => {
    if (imageFile.size > MAX_UPLOAD_SIZE) {
      showErrorToast(`Selected file can't be uploaded too large`)
    }

    if (imageFile.size > MAX_COMPRESSED_SIZE) {
      try {
        const compressedFile = await imageCompression(imageFile, {
          maxSizeMB: MAX_COMPRESSED_SIZE / 1024 / 1024,
          maxWidthOrHeight: 256,
          useWebWorker: true,
          fileType: 'image/webp',
          initialQuality: 0.75,
        })
        imageFile = compressedFile
      } catch (error) {
        showErrorToast('An error occurred while compressing the image. Please try again')
        return
      }
    }

    const base64 = await imageCompression.getDataUrlFromFile(imageFile)
    setProfilePicture(base64)
    props.onChange?.(base64)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ACCEPTED_FILES,
    maxSize: MAX_UPLOAD_SIZE,
    multiple: false,
    onDropRejected: () => showErrorToast(`Selected file can't be uploaded, it might be too large or not an image`),
    onDrop: ([file]: File[]) => {
      if (!file) {
        return
      }
      void processFile(file)
    },
  })

  return (
    <Field label="Profile Picture" className="ml-2" labelClassName={props.className}>
      <div
        {...getRootProps()}
        className={cx(
          'relative rounded-xl border-2  border-dotted before:left-0',
          'w-max p-2 before:absolute before:left-0 before:top-0 before:w-[100%] before:rounded-xl before:opacity-10',
          {
            ['border-accent-9 before:h-[100%] before:bg-gray-4']: isDragActive,
            ['border-transparent before:bg-transparent']: !isDragActive,
          }
        )}
      >
        <input {...getInputProps()} />
        {!props.children && <img src={profilePicture} alt="Profile picture" className="rounded-xl" />}
        {!!props.children && props.children}

        <Button variant="tertiary" size="small">
          Upload image
        </Button>
      </div>
    </Field>
  )
}
