import type { FileCell } from '../../types'
import type { RenderCellProps } from 'react-data-grid'
import { button, icon, iconContainer } from './File.module.css'
import clsx from 'clsx'
import { Text, Link, Flex, Badge } from '@radix-ui/themes'
import { HoverCard } from '../../../HoverCard'
import { DataListItem, DataListRoot } from '../../../DataList'
import type { Color } from '../../../../utils'
import type { FC } from 'react'
import { AppWindow, AudioLines, CaseSensitive, File, FileText, Image, Video } from 'lucide-react'

type Props = FileCell & { className?: string } & RenderCellProps<unknown>

export const FileCellRenderer = ({ className, url, name, fileId, contentType, createdAt, computed }: Props) => {
  const mimeType = contentType?.split('/')[0] ?? ''

  const fileTypeColor = fileIconProps[mimeType]?.color ?? 'gray'

  return (
    <>
      <HoverCard
        maxWidth={'350px'}
        trigger={
          <Flex gap={'1'} align={'center'} className={clsx(className, { 'computed-cell': computed })}>
            <FileIcon contentType={contentType} />
            <Text size={'1'} className={clsx(className, button)}>
              {name ?? url}
            </Text>
          </Flex>
        }
      >
        <DataListRoot size={'1'}>
          <DataListItem label="Name">
            <Text weight={'medium'}>{name}</Text>
          </DataListItem>
          {/* TODO: Add <Identifier/> link to cdm ressources */}
          <DataListItem label="File ID">
            <Text truncate>{fileId}</Text>
          </DataListItem>
          <DataListItem label="Content Type">
            <Badge color={fileTypeColor} size={'1'}>
              {contentType}
            </Badge>
          </DataListItem>
          <DataListItem label="Created At">{createdAt?.toLocaleString()}</DataListItem>
          <DataListItem label="Download URL">
            <Link href={url} download truncate target="_blank" rel="noopener noreferrer">
              {url}
            </Link>
          </DataListItem>
        </DataListRoot>
      </HoverCard>
    </>
  )
}

// TODO: Move to it's own component when <Icon/> is added to ui-kit
type FileIconProps = { contentType?: string }
export const FileIcon = ({ contentType, ...props }: FileIconProps) => {
  const mimeType = contentType?.split('/')[0] ?? ''

  const { color, icon: Icon } = fileIconProps[mimeType] ?? { color: 'gray', icon: File }

  return (
    <Flex data-accent-color={color} className={iconContainer} {...props}>
      <Icon className={icon} strokeWidth={1.5} />
    </Flex>
  )
}

const fileIconProps: Partial<Record<string, { color: Color; icon: FC<{ className?: string }> }>> = {
  image: { color: 'pink', icon: Image },
  video: { color: 'amber', icon: Video },
  audio: { color: 'violet', icon: AudioLines },
  text: { color: 'blue', icon: FileText },
  application: { color: 'grass', icon: AppWindow },
  font: { color: 'orange', icon: CaseSensitive },
}
