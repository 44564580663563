import _ from 'lodash'
import { Field, RuleType } from 'react-querybuilder'
import { QBSchema, QBFields } from './types'

export const jsonSchemaToQBFields = (
  schema: QBSchema,
  output: QBFields = {},
  parentKey = '',
  parentLabel = '',
  level = 0
): QBFields => {
  Object.entries(schema.properties || {}).forEach(([key, schema]: [string, any]) => {
    const label = schema['x-zui']?.title || key
    const fullPath = parentKey ? `${parentKey}.${key}` : key
    const fullLabel = parentLabel ? `${parentLabel}.${label}` : label

    // we only support the first level for object (limitation of mongo-to-postgres)
    if (schema.type === 'object' && level === 0) {
      jsonSchemaToQBFields(schema, output, fullPath, fullLabel, level + 1)
    }

    const type = schema.type === 'string' && schema.format === 'date-time' ? 'date' : schema.type

    output[fullPath] = {
      id: fullPath,
      name: fullPath,
      label: fullLabel,
      inputType: type,
      values: schema.enum?.map((enumValue: string) => ({ value: enumValue, label: enumValue })) ?? [],
      schema,
      validator: getValidatorType(type),
    } satisfies Field
  })

  return output
}

// eslint-disable-next-line no-control-regex
export const stripControlCharacters = (str: string) => str.replace(/[\x00-\x1F\x7F]/g, '')

const getValidatorType = (type: string) => (rule: RuleType) => {
  if (rule.operator === 'null' || rule.operator === 'notNull') {
    return { valid: true }
  }

  if (
    ['contains', 'beginsWith', 'endsWith', 'doesNotContain', 'doesNotBeginWith', 'doesNotEndWith'].includes(
      rule.operator
    )
  ) {
    if (rule.value === undefined || rule.value === null || rule.value === '') {
      return {
        valid: false,
        reasons: ['The field requires a non-empty string. Please ensure the value is not blank.'],
      }
    }
  }

  if (rule.operator === 'in' || rule.operator === 'notIn') {
    const strList = typeof rule.value === 'string' ? rule.value.split(',') : []
    const values = _.isArray(rule.value) ? rule.value : strList

    if (values.length === 0) {
      return { valid: false, reasons: ['The list cannot be empty. Ensure at least one item is present.'] }
    }

    if (values.some((value: string) => value === '')) {
      return { valid: false, reasons: ['All list items must contain a value. Empty items are not permitted.'] }
    }
  }

  if (type === 'date' && !rule.value) {
    return { valid: false, reasons: ['This field requires a date value. Please provide a valid date.'] }
  }

  if (rule.operator === 'between') {
    if (!Array.isArray(rule.value) || rule.value.length !== 2) {
      return {
        valid: false,
        reasons: ['This field requires exactly two values. Please provide an array with two elements.'],
      }
    }

    if (!rule.value[0] || !rule.value[1]) {
      return { valid: false, reasons: ['Both start and end values are necessary. Neither can be left empty.'] }
    }
  }

  return { valid: true }
}

const idField: QBFields = {
  id: {
    id: 'id',
    name: 'id',
    label: 'ID',
    inputType: 'number',
    values: [],
    schema: {},
    validator: getValidatorType('number'),
  },
}

const createdAtField: QBFields = {
  createdAt: {
    id: 'createdAt',
    name: 'createdAt',
    label: 'Created At',
    inputType: 'date',
    values: [],
    schema: {},
    validator: getValidatorType('date'),
  },
}

const updatedAtField: QBFields = {
  updatedAt: {
    id: 'updatedAt',
    name: 'updatedAt',
    label: 'Updated At',
    inputType: 'date',
    values: [],
    schema: {},
    validator: getValidatorType('date'),
  },
}

export const getAllFilterFieldsForSchema = (schema: QBSchema) => {
  const fields = jsonSchemaToQBFields(schema)
  return { ...fields, ...idField, ...createdAtField, ...updatedAtField }
}
