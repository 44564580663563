import { EditableName as EditableText } from '~/components'
import { Field, Pane } from '~/elements'
import { WorkspaceObject } from '~/features/workspaces/types'
import { useUpdateWorkspace, useWorkspaceMembers, useWorkspaces } from '~/hooks'
import { Button, showErrorToast, showSuccessToast } from '@bpinternal/ui-kit'
import { wasResolved } from '~/shared/helpers'
import { LeaveWorkspaceButton } from './LeaveWorkspace'
import { useMemo, useState } from 'react'
import { useCheckWorkspaceHandleAvailability } from '~/hooks/workspaces/useCheckWorkspaceHandle'
import _ from 'lodash'
import { WorkspaceHandleMessage, makeHandleUrl } from './WorkspaceHandleMessage'
import { Permission } from '~/features/users/types'
import { trackEvent } from '~/providers'
import { copyToClipboard } from '~/utils'
import { Copy } from 'lucide-react'
import { Icon } from '~/elementsv2'

type Props = {
  workspace: WorkspaceObject
  isAuthorized: (permission: Permission) => boolean | undefined
  navigateToHome: () => Promise<void>
}

const sanitizeHandle = (handle: string) => handle.toLowerCase().replace(/[^a-z0-9-_]/gi, '')

const CONFIRM_HANDLE_MESSAGE =
  'Are you sure you want to change your workspace handle?\n\nMake sure there are no external links pointing to the old handle, it will not be reachable anymore and will be available for others to claim.'

const WorkspaceDetails = ({ workspace, isAuthorized, navigateToHome }: Props) => {
  const [handleErrorMsg, setHandleError] = useState<string | null>(null)
  const [handleAvailable, setHandleAvailable] = useState<boolean | null>(null)

  const { data: members } = useWorkspaceMembers({ workspaceId: workspace.id })
  const { data: workspaces } = useWorkspaces()
  const { mutateAsync: updateWorkspace } = useUpdateWorkspace()
  const { mutateAsync: checkHandleAvailability } = useCheckWorkspaceHandleAvailability()

  const saveWorkspaceName = async (newName: string) => {
    if (workspaces?.some((x) => x.id !== workspace.id && x.name === newName)) {
      showErrorToast(`Sorry, you already have a workspace named "${newName}", please choose a different name.`)
      return false
    }

    return await wasResolved(
      updateWorkspace({
        id: workspace.id,
        name: newName.trim(),
        onSuccess: () => {
          showSuccessToast(`The workspace has been renamed to "${newName.trim()}".`)
        },
      })
    )
  }

  const updateHandle = async (handle: string) => {
    handle = sanitizeHandle(handle)
    return updateWorkspace(
      { id: workspace.id, handle },
      {
        onSuccess: () => {
          trackEvent({ type: 'update_handle', workspaceId: workspace.id })
          showSuccessToast(
            `The workspace handle has been updated.\nYou can now access this workspace by navigating to ${
              makeHandleUrl(handle).forHumans
            }`
          )
        },
        onError: (error) => {
          showErrorToast(`Failed to update the workspace handle: ${error.message}`)
          throw error
        },
      }
    )
  }

  const confirmUpdateHandle = async (handle: string): Promise<boolean> => {
    // TODO remplace the confirm with a cute modal
    if (!handle || handle === workspace.handle) {
      return false
    }

    if (!workspace.handle || window.confirm(CONFIRM_HANDLE_MESSAGE)) {
      return updateHandle(handle)
        .then(() => {
          setHandleError(null)
          setHandleAvailable(null)
          return true
        })
        .catch(() => false)
    }
    return false
  }

  const debouncedValidate = useMemo(() => {
    return _.debounce(
      async (candidate: string) => {
        candidate = sanitizeHandle(candidate)
        if (!/^[a-z][a-zA-Z0-9_-]+[a-zA-Z0-9]+$/i.test(candidate)) {
          setHandleError('Invalid handle format. Only letters, numbers, dashes and underscores are allowed.')
          return false
        }

        const avalability = await checkHandleAvailability({ handle: candidate })
        if (!avalability.available) {
          setHandleError(
            `Handle is not available.${
              avalability.suggestions.length ? ` Try one of these: ${avalability.suggestions.join(', ')}` : ''
            }`
          )
        } else {
          setHandleError(null)
        }

        setHandleAvailable(avalability.available)
        return avalability.available
      },
      300,
      { trailing: true }
    )
  }, [])

  return (
    <Pane className="w-full">
      <Pane.Body>
        <h3 className="pb-6 text-lg">Workspace Details</h3>
        <Field label="Display Name" className="mb-2">
          <EditableText
            value={workspace.name}
            onSave={saveWorkspaceName}
            validate={(name) => name.length >= 3 && name.length <= 100}
            readonly={!isAuthorized('workspace.update')}
          />
        </Field>
        <Field label="Handle">
          <EditableText
            value={workspace.handle ?? ''}
            onSave={confirmUpdateHandle}
            onChange={(handleValue) => {
              if (!handleValue) {
                setHandleError('Handle cannot be empty')
              } else {
                void debouncedValidate(handleValue)
              }
            }}
            readonly={!isAuthorized('workspace.update')}
            emptyButtonText="Set Workspace Handle"
            isValid={handleErrorMsg == null}
            onCancel={() => {
              setHandleError(null)
              setHandleAvailable(null)
            }}
          />
          <WorkspaceHandleMessage
            workspaceHandle={workspace.handle}
            errorMessage={handleErrorMsg}
            successMessage={handleAvailable ? 'Handle is available' : null}
          />
        </Field>
        <Field label="Workspace Owner" labelClassName="mt-3 mb-3">
          {members?.find((x) => x.userId === workspace.ownerId)?.email}
        </Field>
        <Field label="Workspace ID" labelClassName="mt-3 mb-3 gap-0.5">
          <Button
            variant="ghost"
            color="gray"
            size={'1'}
            onClick={() => copyToClipboard(workspace.id, 'workspace ID')}
            trailing={<Icon icon={Copy} />}
          >
            {workspace.id}
          </Button>
        </Field>
      </Pane.Body>
      <Pane.Footer className="flex justify-end">
        <LeaveWorkspaceButton workspace={workspace} navigateToHome={navigateToHome} />
      </Pane.Footer>
    </Pane>
  )
}

export { WorkspaceDetails }
