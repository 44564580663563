import { createFileRoute } from '@tanstack/react-router'
import { useSuspenseInfiniteQuery, useSuspenseQuery } from '@tanstack/react-query'
import {
  conversationQueryOptions,
  getIntegrationByNameQueryOptions,
  listConversationsInfiniteQuery,
  listConversationUsersQueryOptions,
  messageQueryOptions,
} from '~/queries'
import { DataListItem, DataListRoot, Icon, JSONTree } from '~/elementsv2'
import { SidebarLayout } from '~/layouts'
import { getStateQueryOptions } from '~/queries/states'
import { Badge, Flex, Separator, Text, Grid, Tabs, Box } from '@radix-ui/themes'
import { DateTime } from 'luxon'
import { Tag } from '~/elementsv2/Tag'
import { z } from 'zod'
import { PropsWithChildren } from 'react'
import {
  Boundary,
  Breadcrumbs,
  Identifier,
  IdentifierDropdownMenu,
  UserBadge,
  type BreadcrumbPage,
  Page,
} from '~/componentsV2'
import { HiOutlineChatBubbleLeft } from 'react-icons/hi2'
import { MessageList } from '~/features/conversations/components/MessageList'
import { ConversationSummary } from '~/features/conversations/components/ConversationSumary'
import { Card } from '@bpinternal/ui-kit'

const conversationsSearchSchema = z.object({
  messageId: z
    .string()
    .optional()
    .catch(() => undefined),
})
export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/conversations/$conversationId')({
  validateSearch: conversationsSearchSchema.parse,
  component: Component,
})

function Component() {
  const { workspaceId, botId, conversationId } = Route.useParams()
  const breadcrumbs: BreadcrumbPage[] = [
    {
      name: 'Conversations',
      to: '/workspaces/$workspaceId/bots/$botId/conversations',
      params: { workspaceId, botId },
      search: {},
    },
    { name: conversationId, params: {}, search: {} },
  ]
  return (
    <Page title={<Breadcrumbs pages={breadcrumbs} size={'4'} />}>
      <Boundary height={'200px'} loaderSize="6">
        <SidebarLayout leftSidebar={<LeftSidebar />} main={<Main />} />
      </Boundary>
    </Page>
  )
}

const Main = () => {
  const { workspaceId, botId, conversationId } = Route.useParams()
  const { messageId } = Route.useSearch()
  const { conversation } = useSuspenseQuery(conversationQueryOptions({ botId, workspaceId, conversationId })).data
  const { users } = useSuspenseQuery(listConversationUsersQueryOptions({ conversationId, botId, workspaceId })).data
  const { state } = useSuspenseQuery(
    getStateQueryOptions({
      botId,
      workspaceId,
      id: conversationId,
      type: 'conversation',
      name: 'agentsConversationVariables',
    })
  ).data

  const integration = useSuspenseQuery(getIntegrationByNameQueryOptions({ name: conversation.integration })).data
  return (
    <Grid gap={'4'} className="@container @4xl:grid-cols-[1fr,auto] @4xl:grid-rows-[auto,1fr]">
      <Card className="flex flex-col gap-6 p-6">
        <Flex gap="2" align={'center'}>
          <Icon size="5" icon={(props) => <img src={integration.iconUrl} {...props} />} />
          <Text>{integration.title}</Text>
          <Text size={'2'} color="gray" ml={'auto'}>
            {DateTime.fromISO(conversation.updatedAt).toRelative()}
          </Text>
        </Flex>
        <Tabs.Root defaultValue="details">
          <Tabs.List>
            <Tabs.Trigger value="details">Details</Tabs.Trigger>
            <Tabs.Trigger value="json">JSON</Tabs.Trigger>
          </Tabs.List>
          <Box pt="4">
            <Tabs.Content value="details">
              <DataListRoot size={'2'}>
                {state?.payload.SummaryAgent?.summary && (
                  <DataListItem label="Summary">
                    <Text size={'1'} className="line-clamp-6">
                      {state?.payload.SummaryAgent?.summary}
                    </Text>
                  </DataListItem>
                )}
                <DataListItem align={'center'} label="Conversation ID">
                  <Identifier id={conversation.id} />
                </DataListItem>
                <DataListItem label="Channel">
                  <Badge variant="surface">{conversation.channel}</Badge>
                </DataListItem>
                <DataListItem label="Updated At">{DateTime.fromISO(conversation.updatedAt).toHTTP()}</DataListItem>
                {(users?.length ?? 0) > 0 && (
                  <DataListItem label="Participants">
                    <Flex wrap={'wrap'} gap={'2'}>
                      {users?.map(({ name, pictureUrl, id }) => (
                        <UserBadge className="max-w-36" key={id} name={name} pictureUrl={pictureUrl} userId={id} />
                      ))}
                    </Flex>
                  </DataListItem>
                )}
                <DataListItem align={'start'} label="Tags">
                  <Flex className="w-full" wrap={'wrap'} gap={'2'}>
                    {Object.entries(conversation.tags)
                      .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                      .map(([key, value]) => (
                        <IdentifierDropdownMenu key={key} path={key} value={value}>
                          <Tag clickable className="max-w-full" label={key} value={value} />
                        </IdentifierDropdownMenu>
                      ))}
                  </Flex>
                </DataListItem>
              </DataListRoot>
            </Tabs.Content>
            <Tabs.Content value="json">
              <JSONTree data={conversation} hideRoot />
            </Tabs.Content>
          </Box>
        </Tabs.Root>
      </Card>
      <MessageList
        className="max-h-200 min-h-36 @4xl:row-span-2 @4xl:min-h-full @4xl:w-[400px]"
        botId={botId}
        conversationId={conversation.id}
        workspaceId={workspaceId}
        messageId={messageId}
      />
      {messageId ? (
        <Boundary className="h-64" loaderSize="6">
          <MessageCard messageId={messageId} className="min-h-36 p-6 pt-3" />
        </Boundary>
      ) : (
        <Card className="flex min-h-36 items-center justify-center border-dashed">
          <Flex align={'center'} gap={'2'} className="opacity-60">
            <Icon color="gray" size="4" icon={HiOutlineChatBubbleLeft} />
            <Text color="gray">No message selected</Text>
          </Flex>
        </Card>
      )}
    </Grid>
  )
}

const LeftSidebar = () => {
  const { workspaceId, botId, conversationId } = Route.useParams()
  const { conversation } = useSuspenseQuery(conversationQueryOptions({ botId, workspaceId, conversationId })).data
  const integration = useSuspenseQuery(
    getIntegrationByNameQueryOptions({ name: conversation.integration, workspaceId })
  ).data
  const { users } = useSuspenseQuery(listConversationUsersQueryOptions({ conversationId, botId, workspaceId })).data

  const { data: fetchedConversationsFromSameIntegration } = useSuspenseInfiniteQuery(
    listConversationsInfiniteQuery({ botId, workspaceId, integrationName: conversation.integration })
  )
  const { data: fetchedConversationsWithSameUser } = useSuspenseInfiniteQuery(
    listConversationsInfiniteQuery({ botId, workspaceId, participantIds: users.map((u) => u.id) })
  )

  const conversationsFromSameIntegration = (
    fetchedConversationsFromSameIntegration.pages?.flatMap((page) => page.conversations) ?? []
  ).filter((conv) => conv.id !== conversationId)

  const conversationsWithSameUser = (
    fetchedConversationsWithSameUser.pages?.flatMap((page) => page.conversations) ?? []
  )
    .filter(
      (conv) => conv.id !== conversationId && conversationsFromSameIntegration.findIndex((c) => c.id === conv.id) === -1
    )
    .sort((a, b) => DateTime.fromISO(b.updatedAt).toMillis() - DateTime.fromISO(a.updatedAt).toMillis())

  return (
    <Flex className="flex flex-col gap-4">
      <Flex direction={'column'} gap={'4'}>
        <Text size={'2'} weight={'medium'}>
          More from {integration.title}
        </Text>
        {conversationsFromSameIntegration.slice(0, 4).map((conv) => (
          <ConversationSummary key={conv.id} conversation={conv} botId={botId} workspaceId={workspaceId} />
        ))}
      </Flex>
      {conversationsWithSameUser.length > 0 && (
        <>
          <Separator size={'4'} />
          <Flex direction={'column'} gap={'4'}>
            <Text size={'2'} weight={'medium'}>
              More from the same participants
            </Text>
            {conversationsWithSameUser.slice(0, 4).map((conv) => (
              <ConversationSummary key={conv.id} conversation={conv} botId={botId} workspaceId={workspaceId} />
            ))}
          </Flex>
        </>
      )}
    </Flex>
  )
}

type MessageCardProps = PropsWithChildren<{
  messageId: string
  className?: string
}>
const MessageCard = ({ messageId, ...props }: MessageCardProps) => {
  const { botId, workspaceId } = Route.useParams()
  const { message } = useSuspenseQuery(messageQueryOptions({ messageId, botId, workspaceId })).data
  return (
    <Card {...props}>
      <Tabs.Root defaultValue="details">
        <Tabs.List>
          <Tabs.Trigger value="details">Details</Tabs.Trigger>
          <Tabs.Trigger value="json">JSON</Tabs.Trigger>
        </Tabs.List>
        <Box pt="4">
          <Tabs.Content value="details">
            <DataListRoot size={'2'}>
              <DataListItem align={'center'} label="Message ID">
                <Identifier prefix="msg" id={message.id} />
              </DataListItem>
              <DataListItem label="Direction">
                <Badge variant="surface" color="gray">
                  {message.direction}
                </Badge>
              </DataListItem>
              <DataListItem label="Created At">{DateTime.fromISO(message.createdAt).toHTTP()}</DataListItem>
              <DataListItem align={'start'} label="Tags">
                <Flex className="w-full" wrap={'wrap'} gap={'2'}>
                  {Object.entries(message.tags)
                    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                    .map(([key, value]) => (
                      <IdentifierDropdownMenu key={key} path={key} value={value}>
                        <Tag className="max-w-full hover:brightness-[.98]" label={key} value={value} />
                      </IdentifierDropdownMenu>
                    ))}
                </Flex>
              </DataListItem>
            </DataListRoot>
          </Tabs.Content>
          <Tabs.Content value="json">
            <JSONTree data={message} hideRoot />
          </Tabs.Content>
        </Box>
      </Tabs.Root>
    </Card>
  )
}
