import { useMutation } from '@tanstack/react-query'
import { getIntegrationsFromBot } from '../../features/bots/services'
import { showErrorToast, showSuccessToast } from '@bpinternal/ui-kit'
import { useSuspenseQuery } from '~/services'
import { useUpdateBot, useUploadFile } from '~/hooks'
import { useWebchatStore } from '~/stores'
import { isBase64 } from '~/utils'
import { Base64 } from 'js-base64'
import { nanoid } from 'nanoid'
import { getApiClientForBot } from '~/client'

export function useUpdateWebchat({ botId, workspaceId }: { botId: string; workspaceId: string }) {
  const bot = useSuspenseQuery('workspaces_/$workspaceId_/bots_/$botId_', { botId, workspaceId }).data
  const webchatIntegration = getIntegrationsFromBot(bot).find((bi) => bi.name === 'webchat')

  const { mutateAsync: updateBotAsync } = useUpdateBot()
  const setDirty = useWebchatStore((state) => state.setDirty)
  const { mutateAsync: uploadFile } = useUploadFile({ botId, workspaceId })

  return useMutation({
    mutationFn: async (configuration: Record<string, any> & { showToast?: boolean }) => {
      if (!webchatIntegration) {
        return
      }

      let webchatConfig: Record<string, any>
      const { additionalStylesheet, showToast, ...config } = configuration

      if (additionalStylesheet) {
        const {
          file: { url },
        } = await getApiClientForBot({ botId, workspaceId }).uploadFile({
          key: 'webchatAdditionalStyles.css',
          content: additionalStylesheet,
          accessPolicies: ['public_content'],
          contentType: 'text/css',
          tags: {
            webchatAdditionalStylesVersion: '1',
            source: 'integration',
            integrationName: 'webchat',
            system: 'true',
          },
          publicContentImmediatelyAccessible: true,
        })
        webchatConfig = {
          ...config,
          additionalStylesheet: undefined,
          additionalStylesheetUrl: url,
          additionalStylesheetUrlContent: undefined,
        }
      } else {
        webchatConfig = { ...config, additionalStylesheetUrlContent: undefined }
      }

      // If avatars are base64, we need to upload them to S3. This fixes a bug we had where the base64 was too long for the URL
      if (webchatConfig.avatarUrl && isBase64(webchatConfig.avatarUrl)) {
        const [type, content] = webchatConfig.avatarUrl.split(';base64,')
        const avatar = Base64.toUint8Array(content)

        const file = await uploadFile({
          key: `bot-${botId}-avatar-${nanoid(6)}`,
          content: avatar,
          accessPolicies: ['public_content'],
          contentType: type.replace('data:', ''),
          publicContentImmediatelyAccessible: true,
          tags: { source: 'integration', integration: 'webchat' },
        })

        if (file) {
          webchatConfig.avatarUrl = file.file.url
        }
      }

      if (webchatConfig.botAvatarUrl && isBase64(webchatConfig.botAvatarUrl)) {
        const [type, content] = webchatConfig.botAvatarUrl.split(';base64,')
        const avatar = Base64.toUint8Array(content)

        const file = await uploadFile({
          key: `bot-${botId}-avatar-${nanoid(6)}`,
          content: avatar,
          accessPolicies: ['public_content'],
          contentType: type.replace('data:', ''),
          publicContentImmediatelyAccessible: true,
          tags: { source: 'integration', integration: 'webchat' },
        })
        if (file) {
          webchatConfig.botAvatarUrl = file.file.url
        }
      }
      await updateBotAsync({
        id: botId,
        workspaceId,
        integrations: {
          [webchatIntegration.id]: { enabled: webchatIntegration.enabled, configuration: webchatConfig },
        },
      })

      if (showToast === false) {
        return
      }
      showSuccessToast('The integration configuration has been updated.')
    },
    onSuccess: async () => {
      setDirty(false)
    },
    onError: (error: Error, { onError }) => {
      showErrorToast(error.message)
      onError?.()
    },
  })
}
