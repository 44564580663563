import clsx from 'clsx'
import { addYears, format, isSameMonth } from 'date-fns'
import { ChevronLeft, ChevronRight, ChevronsLeftIcon, ChevronsRight } from 'lucide-react'
import { useRef } from 'react'
import {
  DayPicker,
  useDayPicker,
  useDayRender,
  useNavigation,
  type DayPickerRangeProps,
  type DayPickerSingleProps,
  type DayProps,
  type Matcher,
} from 'react-day-picker'
import { IconButton } from '../IconButton'
import * as clalendarClasses from './calendar.module.css'
import { Button } from '../Button'
import { Flex, Text } from '@radix-ui/themes'

type OmitKeys<T, K extends keyof T> = {
  [P in keyof T as P extends K ? never : P]: T[P]
}

type KeysToOmit = 'showWeekNumber' | 'captionLayout' | 'mode'

type SingleProps = OmitKeys<DayPickerSingleProps, KeysToOmit>
type RangeProps = OmitKeys<DayPickerRangeProps, KeysToOmit>

type CalendarProps =
  | ({
      mode: 'single'
    } & SingleProps)
  | ({
      mode?: undefined
    } & SingleProps)
  | ({
      mode: 'range'
    } & RangeProps)

const Calendar = ({
  mode = 'single',
  weekStartsOn = 1,
  numberOfMonths = 1,
  enableYearNavigation = false,
  disableNavigation,
  locale,
  className,
  classNames,
  ...props
}: CalendarProps & { enableYearNavigation?: boolean }) => {
  const { isToday, isOutside, ...classes } = clalendarClasses
  return (
    <DayPicker
      mode={mode}
      weekStartsOn={weekStartsOn}
      numberOfMonths={numberOfMonths}
      locale={locale}
      showOutsideDays={numberOfMonths === 1}
      className={clsx(className)}
      classNames={{
        ...classes,
        ...classNames,
      }}
      components={{
        IconLeft: () => <ChevronLeft aria-hidden="true" className="size-4" />,
        IconRight: () => <ChevronRight aria-hidden="true" className="size-4" />,
        Caption: ({ ...props }) => {
          const { goToMonth, nextMonth, previousMonth, currentMonth, displayMonths } = useNavigation()
          const { numberOfMonths, fromDate, toDate } = useDayPicker()

          const displayIndex = displayMonths.findIndex((month) => isSameMonth(props.displayMonth, month))
          const isFirst = displayIndex === 0
          const isLast = displayIndex === displayMonths.length - 1

          const hideNextButton = numberOfMonths > 1 && (isFirst || !isLast)
          const hidePreviousButton = numberOfMonths > 1 && (isLast || !isFirst)

          const goToPreviousYear = () => {
            const targetMonth = addYears(currentMonth, -1)
            if (previousMonth && (!fromDate || targetMonth.getTime() >= fromDate.getTime())) {
              goToMonth(targetMonth)
            }
          }

          const goToNextYear = () => {
            const targetMonth = addYears(currentMonth, 1)
            if (nextMonth && (!toDate || targetMonth.getTime() <= toDate.getTime())) {
              goToMonth(targetMonth)
            }
          }

          return (
            <Flex align={'center'} justify={'between'}>
              <Flex align={'center'} gap={'1'}>
                {enableYearNavigation && !hidePreviousButton && (
                  <IconButton
                    variant={'minimal'}
                    size={'1'}
                    color="gray"
                    disabled={
                      disableNavigation ||
                      !previousMonth ||
                      (fromDate && addYears(currentMonth, -1).getTime() < fromDate.getTime())
                    }
                    aria-label="Go to previous year"
                    onClick={goToPreviousYear}
                    icon={ChevronsLeftIcon}
                  />
                )}
                {!hidePreviousButton && (
                  <IconButton
                    variant={'minimal'}
                    size={'1'}
                    color="gray"
                    disabled={disableNavigation || !previousMonth}
                    aria-label="Go to previous month"
                    onClick={() => previousMonth && goToMonth(previousMonth)}
                    icon={ChevronLeft}
                  />
                )}
              </Flex>

              <Text role="presentation" size={'2'} weight={'medium'}>
                {format(props.displayMonth, 'LLLL yyy', { locale })}
              </Text>

              <Flex align={'center'} gap={'1'}>
                {!hideNextButton && (
                  <IconButton
                    variant={'minimal'}
                    size={'1'}
                    color="gray"
                    disabled={disableNavigation || !nextMonth}
                    aria-label="Go to next month"
                    onClick={() => nextMonth && goToMonth(nextMonth)}
                    icon={ChevronRight}
                  />
                )}
                {enableYearNavigation && !hideNextButton && (
                  <IconButton
                    variant={'minimal'}
                    size={'1'}
                    color="gray"
                    disabled={
                      disableNavigation ||
                      !nextMonth ||
                      (toDate && addYears(currentMonth, 1).getTime() > toDate.getTime())
                    }
                    aria-label="Go to next year"
                    onClick={goToNextYear}
                    icon={ChevronsRight}
                  />
                )}
              </Flex>
            </Flex>
          )
        },
        Day: ({ date, displayMonth }: DayProps) => {
          const buttonRef = useRef<HTMLButtonElement>(null)
          const { activeModifiers, buttonProps, divProps, isButton, isHidden } = useDayRender(
            date,
            displayMonth,
            buttonRef
          )

          const { selected, today, disabled, range_middle, outside } = activeModifiers

          if (isHidden) {
            return <></>
          }

          if (!isButton) {
            return <div {...divProps} className={clsx('flex items-center justify-center', divProps.className)} />
          }

          const { children: buttonChildren, className: buttonClassName, ...buttonPropsRest } = buttonProps

          return (
            <Button
              ref={buttonRef}
              disabled={disabled}
              {...buttonPropsRest}
              className={clsx(buttonClassName, today && isToday, outside && isOutside)}
              type="button"
              color={selected && !range_middle ? undefined : 'gray'}
              variant={range_middle ? 'soft' : selected ? 'solid' : 'minimal'}
              size={'1'}
            >
              {buttonChildren}
            </Button>
          )
        },
      }}
      tremor-id="tremor-raw"
      {...(props as SingleProps & RangeProps)}
    />
  )
}

Calendar.displayName = 'Calendar'

export { Calendar, type Matcher }
