import clsx from 'clsx'
import type { RenderCellProps } from 'react-data-grid'
import type { DefaultDataGridCells } from '../types'
import type { FC } from 'react'
import { fadeIn } from '../data-grid.module.css'

import { PrimitiveCellRenderer } from './PrimitiveCellRenderer'
import { ArrayCellRenderer } from './Array'

type Props = RenderCellProps<any, any> & {
  className?: string
  customRenderer?: Record<string, { renderCell: FC<RenderCellProps<unknown, unknown>> }>
}

export const CellRenderer = <R,>({ className, customRenderer, ...props }: Props) => {
  const { column, row } = props
  const cellProps = row[column.key as keyof R] as DefaultDataGridCells

  switch (cellProps.type) {
    case 'array':
      return <ArrayCellRenderer {...cellProps} {...props} className={clsx(className, fadeIn)} />
    default:
      return <PrimitiveCellRenderer {...props} className={clsx(className, fadeIn)} customRenderer={customRenderer} />
  }
}
