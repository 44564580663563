import { Flex, Text } from '@radix-ui/themes'
import { useSuspenseInfiniteQuery, useSuspenseQuery as useTanstackSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute, Link } from '@tanstack/react-router'
import { z } from 'zod'
import { EmptyState, Icon } from '~/elementsv2'
import { HiCheck } from 'react-icons/hi2'
import HITLMessage from '~/assets/hitl-message.svg?react'
import { ConversationDetails, ConversationList } from '~/features/hitl/components'
import { SidebarLayout } from '~/layoutsV2'
import { getIntegrationByNameQueryOptions, listHitlConversationsInfiniteQuery } from '~/queries'

import { getClient, HITLClient } from '~/features/hitl/client'
import { FC, useEffect, useState } from 'react'
import { useAsync } from 'react-use'
import { HITLIntegrationNotEnabledError } from '~/features/hitl/client/errors'
import { useFeature, useUpdateBot } from '~/hooks'

import { useSuspenseQuery } from '~/services'
import { HITLTabs } from '~/features/hitl/components/HITLTabs'
import { cn } from '~/utils'
import { Page } from '~/componentsV2'
import { Button, showDialog, showSuccessToast } from '@bpinternal/ui-kit'
import { match } from 'ts-pattern'
import HubspotForm from 'react-hubspot-form'

const paramsSchema = z.object({
  conversationId: z
    .string()
    .optional()
    .catch(() => undefined),
})

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/hitl')({
  component: Component,
  validateSearch: paramsSchema,
})

function Component() {
  const { botId, workspaceId } = Route.useParams()
  const { plan } = useSuspenseQuery('workspaces_/$workspaceId_', { workspaceId }).data
  const [integrationNotInstalled, setIntegrationNotInstalled] = useState(false)
  const [hitlClient, setHitlClient] = useState<HITLClient>()
  const isWhiteListed = useFeature('hitlWhitelist')

  const initHitlClient = async () => {
    if (hitlClient?.workspaceId === workspaceId && hitlClient?.botId === botId) {
      return
    }
    try {
      const client = await getClient({ workspaceId, botId })
      setHitlClient(client)
    } catch (error) {
      if (error instanceof HITLIntegrationNotEnabledError) {
        setIntegrationNotInstalled(true)
      }
    }
  }

  useAsync(initHitlClient, [workspaceId, botId])

  return (
    <Page title="Human in the loop" className="@container">
      {match({
        plan,
        isWhiteListed,
        integrationNotInstalled,
      })
        // Case: Community plan + not whitelisted
        .with({ plan: 'community', isWhiteListed: false }, () => <UpgradePlanRequestFreeTrial />)
        // Case: Community/Team/Ent plan + HITL not installed
        .with({ integrationNotInstalled: true }, () => (
          <InstallHITL setIntegrationNotInstalled={setIntegrationNotInstalled} initHitlClient={initHitlClient} />
        ))
        // Case: Community/Team/Ent plan + HITL installed
        .with({ integrationNotInstalled: false }, () => <HITLDashboard />)
        .exhaustive()}
    </Page>
  )
}

const UpgradePlanRequestFreeTrial = () => {
  const { workspaceId } = Route.useParams()

  return (
    <Flex className="h-[55vh] w-full" align={'center'} justify={'center'}>
      <Flex className={'rounded-md border border-gray-5 p-16'} align={'center'} style={{ width: '877px' }}>
        <Flex direction={'column'} gap={'6'} width={'1/2'}>
          <Text size="7" weight={'medium'}>
            Take over conversations from the dashboard.
          </Text>
          <div>
            {[
              'Identify escalation criteria',
              'Escalate conversations to human agents',
              'Interact directly with end users',
              'Assign conversations to specific agent',
              'Filter conversations by channel or integration',
              'Pass conversations back to AI agents',
            ].map((feature) => (
              <Flex key={feature} align={'center'} gap={'2'}>
                <Icon icon={HiCheck} color="green" />
                <Text color="gray">{feature}</Text>
              </Flex>
            ))}
          </div>
          <Flex gap={'3'}>
            <Link to="/workspaces/$workspaceId/settings/billing/plans" params={{ workspaceId: workspaceId }}>
              <Button color="green">Upgrade Plan</Button>
            </Link>
            <button
              onClick={() =>
                showDialog(({ close }) => <RequestHITLTrialForm close={close} />, { title: 'Request Free HITL Trial' })
              }
            >
              or request Free Trial
            </button>
          </Flex>
        </Flex>
        <HITLMessage className={'w-9/12'} />
      </Flex>
    </Flex>
  )
}

type RequestHITLTrialFormProps = {
  close: () => void
}

const RequestHITLTrialForm: FC<RequestHITLTrialFormProps> = ({ close }) => {
  return (
    <div className="min-h-[34rem] pt-4" id="hubspot-form-test">
      <HubspotForm
        portalId="20692578"
        formId="6fa0f2d9-c875-46d7-928c-3850d16f3c8b"
        onSubmit={close}
        loading={<div>Loading...</div>}
      />
    </div>
  )
}

type InstallHITLProps = {
  setIntegrationNotInstalled: (value: boolean) => void
  initHitlClient: () => Promise<void>
}

const InstallHITL: FC<InstallHITLProps> = ({ setIntegrationNotInstalled, initHitlClient }) => {
  const { botId, workspaceId } = Route.useParams()
  const { mutate: updateBot } = useUpdateBot()
  const [integrationInstalling, setIntegrationInstalling] = useState(false)
  const hitlIntegration = useTanstackSuspenseQuery(getIntegrationByNameQueryOptions({ name: 'hitl', workspaceId })).data

  const installHITLIntegration = async () => {
    setIntegrationInstalling(true)
    updateBot({
      id: botId,
      workspaceId,
      integrations: {
        [hitlIntegration.id]: { enabled: true, configuration: {} },
      },
      onSuccess: async () => {
        await initHitlClient()
        setIntegrationNotInstalled(false)
        showSuccessToast('HITL integration installed.')
        setIntegrationInstalling(false)
      },
    })
  }

  return (
    <Flex className="h-[55vh] w-full" align={'center'} justify={'center'}>
      <EmptyState
        className="m-auto"
        title="Missing HITL Integration"
        description={
          <span>
            <strong>Human in the Loop</strong> requires an integration to be installed and enabled.
          </span>
        }
        primaryAction={
          <Button loading={integrationInstalling} onClick={installHITLIntegration}>
            <span>Install & enable HITL</span>
          </Button>
        }
      />
    </Flex>
  )
}

const HITLDashboard: FC = () => {
  const { botId, workspaceId } = Route.useParams()
  const { conversationId } = Route.useSearch()

  const { data: fetchedConversations, refetch } = useSuspenseInfiniteQuery(
    listHitlConversationsInfiniteQuery({ botId, workspaceId })
  )

  useEffect(() => {
    const i = setInterval(refetch, 5000) // yes, long polling..
    return () => clearInterval(i)
  }, [])

  const allConversations = fetchedConversations.pages?.flatMap((page) => page.conversations) ?? []
  const currentConversation = allConversations.find((c) => c.id === conversationId)

  return (
    <SidebarLayout
      main={
        allConversations.length === 0 ? (
          <Flex className="h-[55vh] w-full" align={'center'} justify={'center'}>
            <EmptyState
              className="m-auto"
              title="No Conversations"
              description="No conversations have been escalated yet, your bot is doing a great job!"
            />
          </Flex>
        ) : currentConversation ? (
          <HITLTabs botId={botId} workspaceId={workspaceId} downstreamConversation={currentConversation} />
        ) : (
          <Flex className="h-[55vh] w-full" align={'center'} justify={'center'}>
            <EmptyState
              className="m-auto"
              title="No conversation selected"
              description="Select a conversation from the list to start helping your users."
            />
          </Flex>
        )
      }
      leftSidebarSize="3"
      leftSidebar={
        allConversations.length === 0 ? (
          <div />
        ) : (
          <>
            {currentConversation && (
              <div className="mb-6 @6xl:hidden">
                <ConversationDetails
                  botId={botId}
                  workspaceId={workspaceId}
                  downstreamConversation={currentConversation}
                />
              </div>
            )}
            <ConversationList
              downstreamConversations={allConversations}
              botId={botId}
              workspaceId={workspaceId}
              currentConversationId={conversationId}
              className={cn({
                'h-[calc(75vh-18rem)] @6xl:h-[calc(100vh-18rem)]': !!currentConversation,
                'h-[calc(100vh-18rem)]': !currentConversation,
              })}
            />
          </>
        )
      }
      rightSidebar={
        currentConversation ? (
          <ConversationDetails botId={botId} workspaceId={workspaceId} downstreamConversation={currentConversation} />
        ) : (
          <div />
        )
      }
      rightSidebarClassName="hidden @6xl:flex"
    />
  )
}
