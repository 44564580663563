import type { ArrayCell } from '../../types'
import type { RenderCellProps } from 'react-data-grid'
import { PrimitiveCellRenderer } from '../PrimitiveCellRenderer'
import clsx from 'clsx'
import { Flex } from '@radix-ui/themes'

type Props = ArrayCell & { className?: string } & RenderCellProps<any>

export const ArrayCellRenderer = ({ cells, className, ...props }: Props) => {
  const { column, row } = props
  const cellProps = row[column.key] as ArrayCell
  return (
    <Flex>
      {cells.map((cell, index) => (
        <PrimitiveCellRenderer
          {...props}
          {...cell}
          key={index}
          className={clsx(className, 'computed-cell')}
          row={{ ...row, [column.key]: cellProps.cells[index] }}
        />
      ))}
    </Flex>
  )
}
