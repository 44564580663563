import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { Spinner } from '../elementsv2'
import React, { ComponentProps, PropsWithChildren, ReactNode, Suspense, useState } from 'react'
import { useQueryErrorResetBoundary } from '@tanstack/react-query'
import { Box, Flex, Text } from '@radix-ui/themes'
import { EmptyState } from '../elementsv2/EmptyState'
import { showBugReportDialog } from '~/componentsV2/BugReportDialog'
import ErrorIcon from '~/assets/website-maintenance-15.svg?react'
import { config, isHttpUnauthorizedError } from '~/shared'
import { logout } from '~/services'
import { HiOutlineClipboardDocument } from 'react-icons/hi2'
import { copyToClipboard } from '~/utils'
import { useParams } from '@tanstack/react-router'
import { trackEvent } from '~/providers'
import { useCurrentRouteId, useCurrentUser } from '~/hooks'
import { captureException } from '@sentry/react'
import { Button, Callout } from '@bpinternal/ui-kit'

type Props = PropsWithChildren<{
  onError?: (props: FallbackProps) => React.ReactNode
  suspenseFallback?: ComponentProps<typeof Suspense>['fallback']
  width?: ComponentProps<typeof Box>['height']
  height?: ComponentProps<typeof Box>['height']
  className?: string
  loaderSize?: ComponentProps<typeof Spinner>['size']
  showErrorIcon?: boolean
  errorIconSize?: ComponentProps<typeof EmptyState>['iconSize']
  loader?: ReactNode
}>

export const Boundary = ({
  children,
  onError,
  suspenseFallback,
  width,
  height,
  className,
  loaderSize,
  loader,
  showErrorIcon,
  errorIconSize,
}: Props) => {
  const { reset } = useQueryErrorResetBoundary()
  const params = useParams({ strict: false })
  const routeId = useCurrentRouteId()
  const { profilePicture: _, ...user } = useCurrentUser()

  const defaultOnErrorFallback = ({ resetErrorBoundary, error }: FallbackProps) => {
    trackEvent({ type: 'error_event', routeId, userEmail: user.email, userId: user.id, ...params })
    captureException(error, { tags: { routeId, ...params } })
    if (isHttpUnauthorizedError(error) && !config.usePat) {
      // eslint-disable-next-line no-console
      console.debug('Unauthorized error received from API, logging out...')
      logout()
      return null
    }

    return (
      <DefaultErrorEmptyState
        {...{ width, height, className }}
        error={error}
        gap={'5'}
        icon={showErrorIcon ? undefined : () => null}
        iconSize={errorIconSize}
        primaryAction={
          <Button variant="soft" onClick={resetErrorBoundary}>
            Retry
          </Button>
        }
      />
    )
  }
  const defaultSuspenseFallback = (
    <Flex {...{ width, height, className }} justify={'center'} align={'center'}>
      {loader || loader === null ? loader : <Spinner size={loaderSize} />}
    </Flex>
  )

  return (
    <ErrorBoundary onReset={reset} fallbackRender={onError ?? defaultOnErrorFallback}>
      <Suspense fallback={suspenseFallback === undefined ? defaultSuspenseFallback : suspenseFallback}>
        {children}
      </Suspense>
    </ErrorBoundary>
  )
}

export const DefaultErrorEmptyState = ({
  icon = ErrorIcon,
  description = 'Something went wrong. Please try again later.',

  error,
  primaryAction,
  secondaryAction,
  ...props
}: ComponentProps<typeof EmptyState> & { error: any }) => {
  const [reportId, setReportId] = useState<string>()

  return (
    <EmptyState
      {...props}
      gap={'5'}
      icon={icon}
      primaryAction={reportId ? null : primaryAction}
      description={
        reportId ? (
          <Callout
            size={'1'}
            color="blue"
            icon={HiOutlineClipboardDocument}
            className="cursor-pointer hover:bg-accent-4"
            onClick={() => void copyToClipboard(reportId, 'Issue ID')}
          >
            <Text>Your issue has been reported. Click here to copy the issue ID, this will help us track it.</Text>
          </Callout>
        ) : (
          description
        )
      }
      secondaryAction={
        reportId
          ? null
          : (secondaryAction ?? (
              <Button
                size={'1'}
                mt={'-2'}
                variant="ghost"
                color="gray"
                onClick={() => showBugReportDialog({ error, onSubmit: ({ id }) => setReportId(id) })}
              >
                Report Issue
              </Button>
            ))
      }
    />
  )
}
