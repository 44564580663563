import { useUpdateBot } from '../../hooks'
import { showSuccessToast } from '@bpinternal/ui-kit'

type Props = { botId: string; workspaceId: string; name: string; onSuccess?: () => void }

export function useRenameBot() {
  const { mutate: updateBot, ...rest } = useUpdateBot()

  const renameBot = ({ botId, workspaceId, name, onSuccess }: Props) => {
    return updateBot({
      id: botId,
      workspaceId,
      name,
      onSuccess: () => {
        showSuccessToast(`You have successfully renamed your bot to ${name}.`)
        onSuccess?.()
      },
    })
  }

  return { mutate: renameBot, ...rest }
}
