import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { getIntegrationsFromBot } from '~/features/bots/services'
import { webchatIntegrationConfigSchema, type WebchatIntegrationConfig } from '~/features/webchat/schemas'
import { useSuspenseQuery, getQueryOptions } from '~/services'

export const useWebchatConfig = ({ botId, workspaceId }: { botId: string; workspaceId: string }) => {
  const bot = useSuspenseQuery('workspaces_/$workspaceId_/bots_/$botId_', { botId, workspaceId }).data
  const webchatIntegration = getIntegrationsFromBot(bot).find((bi) => bi.name === 'webchat')
  const baseConfig = webchatIntegrationConfigSchema.safeParse(webchatIntegration?.configuration ?? {})
  const { data: files } = useSuspenseQuery('workspaces_/$workspaceId_/bots_/$botId_/files/$ids/$tags/all', {
    botId,
    workspaceId,
    tags: { webchatAdditionalStylesVersion: '1' },
  })

  const stylesUrl = files?.[0]?.url

  const queryOptions = getQueryOptions('fetch/$url', { url: stylesUrl ?? '' })
  const { data: styles } = useQuery({ ...queryOptions, staleTime: 0 })

  const [config, setConfig] = useState<WebchatIntegrationConfig & { additionalStylesheetUrlContent?: string }>(
    baseConfig.success
      ? { ...baseConfig.data, additionalStylesheetUrlContent: styles }
      : { frontendVersion: 'v1', theme: 'eggplant' }
  )

  useEffect(() => {
    setConfig(
      baseConfig.success
        ? { ...baseConfig.data, additionalStylesheetUrlContent: styles }
        : { frontendVersion: 'v1', theme: 'eggplant' }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bot, styles])

  return [config, setConfig] as const
}
