import { createFileRoute } from '@tanstack/react-router'
import { getQueryOptions, useQuery, useSuspenseQuery } from '~/services'
import { DataGrid, DefaultDataGridCells, Button, IconButton } from '@bpinternal/ui-kit'
import { ClientOutputs, type File, type Row } from 'botpress-client'
import { match, P } from 'ts-pattern'
import { Page } from '~/componentsV2'
import { ObjectRenderer } from '~/features/tables/components'
import { Flex, Text } from '@radix-ui/themes'
import { ChevronLeft, ChevronRight, FilterIcon, RefreshCw, X } from 'lucide-react'
import { Popover, Icon, Input } from '~/elementsv2'
import z from 'zod'
import { FC, memo, useMemo, useRef, useState, type ComponentProps } from 'react'
import { useDebounce } from 'react-use'
import clsx from 'clsx'
import { toLowerCase, toUpperCase } from '~/utils'
import { useSuspenseQuery as useTanstackSuspenseQuery } from '@tanstack/react-query'
import { userPreferencesQueryOptions } from '~/queries'
import { useSetPreferences } from '~/hooks'
import { useDebounceCallback } from 'usehooks-ts'
import { FilterQueryBuilder, QBResult, QBSchema } from '~/features/tables/components/FilterQueryBuilder'

const DEFAULT_LIMIT = 50
const DEFAULT_OFFSET = 0

const searchParamsSchema = z.object({
  filter: z.record(z.any()).optional(),
  limit: z
    .number()
    .positive()
    .default(DEFAULT_LIMIT)
    .catch(() => DEFAULT_LIMIT),
  group: z.record(z.any()).optional(),
  offset: z
    .number()
    .positive()
    .default(DEFAULT_OFFSET)
    .catch(() => DEFAULT_OFFSET),
  orderBy: z.string().optional(),
  orderDirection: z.enum(['asc', 'desc']).optional(),
  search: z.string().optional(),
})
export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/tables/$tableId')({
  component: Component,
  loaderDeps: ({ search }) => ({
    ...search,
    limit: search.limit ?? DEFAULT_LIMIT,
    offset: search.offset ?? DEFAULT_OFFSET,
  }),
  loader: ({ context, params, deps }) => {
    return Promise.all([
      context.queryClient.prefetchQuery(
        getQueryOptions(
          'workspaces_/$workspaceId_/bots_/$botId_/tables/$table_/rows/$filter/$limit/$group/$offset/$orderBy/$orderDirection/$search',
          {
            botId: params.botId,
            workspaceId: params.workspaceId,
            table: params.tableId,
            ...deps,
          }
        )
      ),
      context.queryClient.ensureQueryData(
        getQueryOptions('workspaces_/$workspaceId_/bots_/$botId_/tables/$table_', {
          botId: params.botId,
          workspaceId: params.workspaceId,
          table: params.tableId,
        })
      ),
    ])
  },

  validateSearch: searchParamsSchema,
})

function Component() {
  const { workspaceId, botId, tableId } = Route.useParams()
  const searchParams = Route.useSearch()
  const navigate = Route.useNavigate()

  const { data: table } = useSuspenseQuery('workspaces_/$workspaceId_/bots_/$botId_/tables/$table_', {
    botId,
    workspaceId,
    table: tableId,
  })

  const { data: columSizes } = useTanstackSuspenseQuery(
    userPreferencesQueryOptions({ path: '$tableId/columnWidths', params: { tableId } })
  )
  const { mutate: setPreferences } = useSetPreferences()

  const {
    data: tableRows,
    isFetching: isFetchingRows,
    refetch: refetchRows,
  } = useQuery(
    'workspaces_/$workspaceId_/bots_/$botId_/tables/$table_/rows/$filter/$limit/$group/$offset/$orderBy/$orderDirection/$search',
    { botId, workspaceId, table: tableId, ...searchParams }
  )

  const currentPage = (searchParams.offset ?? DEFAULT_OFFSET) / (searchParams.limit ?? DEFAULT_LIMIT) + 1
  const totalPages = Math.ceil(table.rows / (searchParams.limit ?? DEFAULT_LIMIT))

  const parsedColumns = parseColumns(table.table, columSizes)

  const filesColumnKeys = parsedColumns.filter(({ type }) => type === 'file').map(({ key }) => key)
  const fileIds = tableRows?.rows.flatMap((row) => filesColumnKeys.map((key) => row[key])).filter(Boolean)

  const { data: files } = useQuery('workspaces_/$workspaceId_/bots_/$botId_/files/$ids/$tags/all', {
    botId,
    workspaceId,
    ids: fileIds,
  })

  const parsedRows = parseRows({
    parsedColumns,
    tableRows: tableRows?.rows ?? [],
    files: files ?? [],
  })

  const skeletonRows = useMemo(() => generateRows({ columns: parsedColumns }), [parsedColumns.length])

  function jumpToPage(page: number) {
    const nextPage = match(page)
      .with(P.number.lt(1), () => 1)
      .with(P.number.gt(totalPages), () => totalPages)
      .with(P.number, () => page)
      .otherwise(() => 1)

    navigate({
      from: Route.fullPath,
      search: { ...searchParams, offset: Math.max(nextPage - 1, 0) * (searchParams.limit ?? DEFAULT_LIMIT) },
    })
  }

  const [desiredPage, setDesiredPage] = useState(currentPage)
  useDebounce(
    () => {
      if (inputRef.current === document.activeElement) {
        return
      }
      jumpToPage(desiredPage)
    },
    250,
    [desiredPage]
  )

  const updateColumnWidth = useDebounceCallback((key: string, width: number) => {
    setPreferences({ path: '$tableId/columnWidths', params: { tableId }, value: { ...columSizes, [key]: width } })
  }, 1000)

  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <Page title={table.table.name}>
      <Flex gap={'4'} className={'pb-2 pl-1'}>
        <ConfigureTableFilter
          schema={table.table.schema}
          initialTableFilter={searchParams.filter}
          onTableFilterUpdated={(newFilter: QBSchema | undefined) => {
            navigate({
              from: Route.fullPath,
              search: { ...searchParams, filter: newFilter },
            })
          }}
        />
        <Button
          variant="soft"
          onClick={() => refetchRows()}
          disabled={isFetchingRows}
          color="gray"
          size={'1'}
          leading={<Icon size="1" muted={isFetchingRows} icon={RefreshCw} />}
        >
          Refresh
        </Button>
      </Flex>
      <Flex direction={'column'} align={'center'} gap={'4'}>
        <MDataGrid
          enableVirtualization={false}
          className={clsx('h-min max-h-[calc(100dvh-450px)]')}
          renderers={{ object: { renderCell: ObjectRenderer } }}
          columns={parsedColumns}
          defaultColumnOptions={{ sortable: true }}
          sortColumns={
            searchParams.orderBy && searchParams.orderDirection
              ? [{ columnKey: searchParams.orderBy, direction: toUpperCase(searchParams.orderDirection) }]
              : []
          }
          onSortColumnsChange={(cols) => {
            const sortColumn = cols[0]

            if (!sortColumn) {
              navigate({
                from: Route.fullPath,
                search: { ...searchParams, orderBy: undefined, orderDirection: undefined },
              })
              return
            }

            const { columnKey: orderBy, direction } = sortColumn
            navigate({
              from: Route.fullPath,
              search: { ...searchParams, orderBy, orderDirection: toLowerCase(direction) },
            })
          }}
          rows={isFetchingRows ? skeletonRows : parsedRows}
          onColumnResize={(idx, width) => {
            const key = parsedColumns[idx]?.key
            if (key) {
              updateColumnWidth(key, width)
            }
          }}
        />
        <Flex gap={'2'} align={'center'}>
          <IconButton
            onClick={() => setDesiredPage((prev) => prev - 1)}
            disabled={desiredPage === 1}
            color="gray"
            size={'1'}
            variant="ghost"
            icon={ChevronLeft}
          />
          <Flex align={'baseline'} gap={'2'}>
            <Input
              ref={inputRef}
              onBlur={(e) => {
                jumpToPage(parseInt(e.target.value))
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  jumpToPage(parseInt(e.currentTarget.value))
                  inputRef.current?.blur()
                }
              }}
              value={desiredPage}
              onChange={(e) => setDesiredPage(parseInt(e.target.value))}
              type="number"
              size={'1'}
              className="w-8"
            />
            <Text color="gray" size={'1'}>
              of {Math.max(totalPages, 1)}
            </Text>
          </Flex>
          <IconButton
            onClick={() => setDesiredPage((prev) => prev + 1)}
            disabled={desiredPage >= totalPages}
            color="gray"
            variant="ghost"
            size={'1'}
            icon={ChevronRight}
          />
        </Flex>
      </Flex>
    </Page>
  )
}

type ConfigureTableFilterProps = {
  schema: ClientOutputs['getTable']['table']['schema']
  initialTableFilter: QBSchema | undefined
  onTableFilterUpdated: (props: QBSchema | undefined) => void
}

const DEFAULT_QUERY_CONFIG: QBResult = Object.freeze({ query: null, rulesCount: 0, isValid: false })

const getLabelFilter = (filters: number) =>
  filters > 0 ? `Filtered by ${filters} rule${filters > 1 ? 's' : ''}` : 'Filter'

const ConfigureTableFilter: FC<ConfigureTableFilterProps> = ({ schema, initialTableFilter, onTableFilterUpdated }) => {
  const initialQueryConfig: QBResult = initialTableFilter
    ? { query: JSON.stringify(initialTableFilter), rulesCount: Object.values(initialTableFilter).length, isValid: true }
    : DEFAULT_QUERY_CONFIG

  const [isOpened, setIsOpened] = useState(false)
  const [activeRules, setActiveRules] = useState<number>(initialQueryConfig.rulesCount)

  const clearAllFilters = () => {
    setActiveRules(0)
    onTableFilterUpdated({})
  }

  return (
    <Popover
      open={isOpened}
      onOpenChange={setIsOpened}
      trigger={
        <Flex align={'center'}>
          <Button
            className={activeRules > 0 ? 'rounded-none rounded-l-md' : 'rounded'}
            variant="soft"
            size={'1'}
            color={activeRules > 0 ? 'grass' : 'blue'}
            leading={<Icon icon={FilterIcon} />}
          >
            {getLabelFilter(activeRules)}
          </Button>
          {activeRules > 0 && (
            <IconButton
              className={activeRules > 0 ? 'rounded-none rounded-r-md' : 'rounded'}
              icon={X}
              size="1"
              variant="soft"
              color={'grass'}
              onClick={(e) => {
                e.stopPropagation()
                clearAllFilters()
              }}
            />
          )}
        </Flex>
      }
      maxWidth={'700px'}
      className="p-0"
    >
      <div>
        <FilterQueryBuilder
          schema={schema}
          initialQueryConfig={initialQueryConfig}
          onCancel={() => setIsOpened(false)}
          onSave={(newQueryConfig) => {
            setActiveRules(newQueryConfig.rulesCount)
            const newTableFilter = (newQueryConfig.query ? JSON.parse(newQueryConfig.query) : undefined) as
              | QBSchema
              | undefined
            onTableFilterUpdated(newTableFilter)
            setIsOpened(false)
          }}
        />
      </div>
    </Popover>
  )
}

// eslint-disable-next-line react/display-name
const MDataGrid = memo(
  <R, SR = unknown, K extends React.Key = React.Key>(props: ComponentProps<typeof DataGrid<R, SR, K>>) => {
    return <DataGrid {...props} />
  },
  (prevProps, nextProps) => {
    const { columns: prevColumns, rows: prevRows, sortColumns: prevSortColumns } = prevProps
    const { columns: nextColumns, rows: nextRows, sortColumns: nextSortColumns } = nextProps

    if (prevSortColumns?.length !== nextSortColumns?.length) {
      return false
    }

    if (JSON.stringify(prevSortColumns) !== JSON.stringify(nextSortColumns)) {
      return false
    }

    if (prevColumns.length !== nextColumns.length || prevRows.length !== nextRows.length) {
      return false
    }
    if (JSON.stringify(prevColumns) !== JSON.stringify(nextColumns)) {
      return false
    }
    if (prevRows.length !== nextRows.length) {
      return false
    }
    if (JSON.stringify(prevRows) !== JSON.stringify(nextRows)) {
      return false
    }

    return true
  }
)

type ParsedColumn = {
  key: string
  name: string
  type: 'string' | 'number' | 'boolean' | 'object' | 'enum' | 'file' | 'date' | 'none'
  enum?: string[] | undefined
  width?: number
  cellClass?: string | undefined
  headerCellClass?: string | undefined
  resizable?: boolean
  sortable?: boolean
  minWidth?: number
}
function parseColumns(
  table: ClientOutputs['getTable']['table'],
  columnSizes: Record<string, number> = {}
): ParsedColumn[] {
  const defaultColumnWidth = 150
  const defaultColumns: ParsedColumn[] = [
    { key: 'id', name: '#', type: 'number', resizable: true, width: columnSizes['id'] ?? DEFAULT_LIMIT },
    {
      key: 'createdAt',
      name: 'Created At',
      type: 'date',
      resizable: true,
      width: columnSizes['createdAt'] ?? defaultColumnWidth,
    },
    {
      key: 'updatedAt',
      name: 'Updated At',
      type: 'date',
      resizable: true,
      width: columnSizes['updatedAt'] ?? defaultColumnWidth,
    },
  ]

  const emptyCol: ParsedColumn[] = [
    {
      key: '$empty',
      name: '',
      type: 'string',
      resizable: true,
      sortable: false,
      minWidth: 0,
    },
  ]

  const sortedColumns = Object.entries(table.schema.properties).sort(
    ([, col1], [, col2]) => col1['x-zui'].index - col2['x-zui'].index
  )

  const columns = sortedColumns.map(([key, colSchema]) => {
    const computed = colSchema['x-zui'].computed
    const type = match({
      type: colSchema.type,
      pattern: colSchema.pattern,
      format: colSchema.format,
      enum: colSchema.enum,
    })
      .with({ pattern: '^file_[0-9A-Z]{26}$' }, () => 'file' as const)
      .with({ type: 'string', format: 'date-time' }, () => 'date' as const)
      .with({ type: 'string', enum: P.array(P.string) }, () => 'enum' as const)
      .with({ type: 'string' }, () => 'string' as const)
      .with({ type: 'number' }, () => 'number' as const)
      .with({ type: 'object' }, () => 'object' as const)
      .with({ type: 'boolean' }, () => 'boolean' as const)
      .otherwise(() => 'none' as const)
    return {
      key,
      name: key,
      type,
      enum: colSchema.enum,
      width: columnSizes[key] ?? defaultColumnWidth,
      cellClass: computed ? 'computed-cell' : undefined,
      headerCellClass: computed ? 'computed-cell' : undefined,
      resizable: true,
    }
  })

  return [...defaultColumns, ...columns, ...emptyCol]
}

function parseRows({
  tableRows,
  parsedColumns,
  files = [],
}: {
  tableRows: Row[]
  parsedColumns: ReturnType<typeof parseColumns>
  files: File[]
  pageSize?: number
}): Record<string, DefaultDataGridCells | { type: 'object'; value: unknown }>[] {
  const filesMap = files.reduce(
    (acc, file) => {
      acc[file.id] = file
      return acc
    },
    {} as Record<string, File>
  )

  const rows = tableRows.map(({ computed, id, createdAt, similarity, stale, updatedAt, ...cells }) => {
    const row: Record<string, DefaultDataGridCells | { type: 'object'; value: unknown }> = {}
    for (const [index, rowSchema] of parsedColumns.entries()) {
      const { key } = rowSchema
      row[key] = match<
        { type: string; value: unknown; enum?: string[] },
        DefaultDataGridCells | { type: 'object'; value: unknown }
      >({
        type: rowSchema.type,
        value: cells[key],
        enum: parsedColumns[index]?.enum,
      })
        .with({ value: null }, () => ({ type: 'null' }))
        .with(
          { type: 'file', value: P.string },
          ({ value }) => {
            const { contentType, key, url, createdAt } = filesMap[value] ?? {}
            const creationDate = createdAt ? new Date(createdAt) : undefined
            return {
              type: 'file',
              fileId: value,
              contentType,
              url,
              createdAt: creationDate,
              name: key,
            } // TODO: Will need to fetch all the files from the files API to get more info
          } // TODO: Will need to fetch all the files from the files API to get more info
        )
        .with({ type: 'enum', value: P.string, enum: P.array(P.string) }, ({ value, enum: enumValues }) => {
          return {
            type: 'enum',
            value,
            options: enumValues.map((option) => ({ value: option })),
          }
        })
        .with({ type: 'string', value: P.string.regex(/^https?:\/\//) }, ({ value }) => ({
          type: 'url',
          url: value,
        }))
        .with({ type: 'string', value: P.string }, ({ value }) => ({
          type: 'string',
          value,
        }))
        .with({ type: 'number', value: P.number }, ({ value }) => ({
          type: 'number',
          value,
        }))
        .with({ type: 'object', value: P.any }, ({ value }) => ({
          type: 'object',
          value,
        }))
        .with({ type: 'date', value: P.string }, ({ value }) => ({
          type: 'date',
          value: new Date(value),
        }))
        .with({ type: 'boolean', value: P.boolean }, ({ value }) => ({
          type: 'boolean',
          value,
        }))
        .otherwise(({ value }) => ({ type: 'string', value: JSON.stringify(value) }))
    }

    row.createdAt = createdAt ? { type: 'date', value: new Date(createdAt) } : { type: 'null' }
    row.updatedAt = updatedAt ? { type: 'date', value: new Date(updatedAt) } : { type: 'null' }
    row.id = { type: 'number', value: id }

    return row
  })

  // TODO: This empty state could be a lot better but I'm just trying to get the basic functionality working and I'm out of time
  return [...rows, ...generateRows({ columns: parsedColumns, type: 'string', count: Math.max(24 - rows.length, 0) })]
}

function generateRows({
  count = DEFAULT_LIMIT,
  type = 'skeleton',
  columns,
}: {
  columns: ReturnType<typeof parseColumns>
  type?: 'skeleton' | 'string'
  count?: number
}) {
  const skeletonRow = columns.reduce(
    (acc, { key }) => {
      acc[key] = type === 'skeleton' ? { type } : { type, value: '' }
      return acc
    },
    {} as Record<string, DefaultDataGridCells>
  )

  return Array.from({ length: count }).map(() => skeletonRow)
}
