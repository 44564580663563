import _ from 'lodash'

// Taken from NLU repo
export function levenshteinSimilarity(a: string, b: string): number {
  const len = Math.max(a.length, b.length)
  const dist = levenshtein(a, b)
  return (len - dist) / len
}

function levenshtein(a: string, b: string): number {
  if (a.length === 0 || b.length === 0) {
    return 0
  }

  if (a.length > b.length) {
    const tmp = a
    a = b
    b = tmp
  }

  let i: number,
    j: number,
    res: number = 0

  const alen = a.length
  const blen = b.length
  const row = _.range(alen + 1)

  let tmp: number
  for (i = 1; i <= blen; i++) {
    res = i
    for (j = 1; j <= alen; j++) {
      tmp = row[j - 1] ?? 0
      row[j - 1] = res
      res = b[i - 1] === a[j - 1] ? tmp : Math.min(tmp + 1, res + 1, row[j] ?? 0 + 1)
    }
  }

  return res
}
