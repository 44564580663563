const darkColors = ['gold', 'bronze', 'gray'] as const
const brightColors = ['sky', 'mint', 'lime', 'yellow', 'amber'] as const

export const regularColors = [
  'tomato',
  'red',
  'ruby',
  'crimson',
  'pink',
  'plum',
  'purple',
  'violet',
  'iris',
  'indigo',
  'blue',
  'cyan',
  'teal',
  'jade',
  'green',
  'grass',
  'brown',
  'orange',
] as const

export const baseColors = [...regularColors, ...brightColors] as const
export const colors = [...baseColors, ...darkColors] as const
export type Color = (typeof colors)[number]
