import { useFlag, useFlags } from '@unleash/proxy-client-react'

const INTEGRATION_FEATURE_TOGGLES = [
  'zendeskIntegration',
  'gmailIntegration',
  'gsheetsIntegration',
  'stripeIntegration',
  'mailchimpIntegration',
  'makeDotComIntegration',
  'googleCalendarIntegration',
  'dalleIntegration',
  'chat-api',
  'discordIntegration',
  'githubIntegration',
  'openaiIntegration',
  'groqIntegration',
  'claudeIntegration',
  'dashboard-hitl',
  'anthropicIntegration',
  'fireworksAiIntegration',
  'cerebrasIntegration',
  'hitlWhitelist',
] as const

export type IntegrationFeatureToggle = (typeof INTEGRATION_FEATURE_TOGGLES)[number]

type FeatureToggle =
  | 'webchatV2'
  | 'chat-api'
  | 'slackNewForm'
  | 'alwaysAlive'
  | 'usageBannerNotifications'
  | 'integrations.publish'
  | 'integrations.selfhosted'
  | 'dashboard-onboarding-experiment'
  | 'dashboardFileUpload'
  | IntegrationFeatureToggle

export function useFeature(featureName: FeatureToggle) {
  return useFlag(featureName)
}

export function useAllFeatures() {
  return useFlags()
}
