import { ComponentProps, ReactNode } from 'react'
import { toast } from 'sonner'
import { Callout } from '../Callout'
import { ThemeColor } from '../../ThemeProvider'
import { callout } from './toaster.module.css'

type showToastProps = {
  color?: ComponentProps<typeof Callout>['color']
  showIcon?: boolean
}

export function showSuccessToast(content: ReactNode, { showIcon = true }: Omit<showToastProps, 'variant'> = {}) {
  return showToast(content, { color: 'grass', showIcon })
}

export function showInfoToast(content: ReactNode, { showIcon = true }: Omit<showToastProps, 'variant'> = {}) {
  return showToast(content, { color: ThemeColor, showIcon })
}

export function showWarningToast(content: ReactNode, { showIcon = true }: Omit<showToastProps, 'variant'> = {}) {
  return showToast(content, { color: 'amber', showIcon })
}

export function showErrorToast(content: ReactNode, { showIcon = true }: Omit<showToastProps, 'variant'> = {}) {
  return showToast(content, { color: 'red', showIcon })
}

export function showToast(content: ReactNode, { color = 'gray' }: showToastProps = {}) {
  return toast.custom(() => (
    <Callout className={callout} color={color}>
      {content}
    </Callout>
  ))
}
