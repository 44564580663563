import type { RenderCellProps } from 'react-data-grid'
import type { PrimitiveDataGridCells } from '../types'
import { TextCellRenderer } from './Text'
import type { FC } from 'react'
import { NumberCellRenderer } from './Number'
import { EnumCellRenderer } from './Enum'
import { LinkCellRenderer } from './Link'
import { FileCellRenderer } from './File'
import { DateCellRenderer } from './Date'
import { NullCellRenderer } from './Null'
import { BooleanCellRenderer } from './Boolean'
import { SkeletonCellRenderer } from './Skeleton'

type Props = RenderCellProps<any, any> & {
  className?: string
  customRenderer?: Record<string, { renderCell: FC<RenderCellProps<unknown, unknown>> }>
}

export const PrimitiveCellRenderer = <R,>({ customRenderer, ...props }: Props) => {
  const { column, row } = props
  const cellProps = row[column.key as keyof R] as PrimitiveDataGridCells

  switch (cellProps.type) {
    case 'null':
      return <NullCellRenderer {...cellProps} {...props} />
    case 'string':
      return <TextCellRenderer {...cellProps} {...props} />
    case 'number':
      return <NumberCellRenderer {...cellProps} {...props} />
    case 'enum':
      return <EnumCellRenderer {...cellProps} {...props} />
    case 'url':
      return <LinkCellRenderer {...cellProps} {...props} />
    case 'file':
      return <FileCellRenderer {...cellProps} {...props} />
    case 'date':
      return <DateCellRenderer {...cellProps} {...props} />
    case 'boolean':
      return <BooleanCellRenderer {...cellProps} {...props} />
    case 'skeleton':
      return <SkeletonCellRenderer {...cellProps} {...props} />

    default:
      return customRenderer?.[(cellProps as { type: string }).type]?.renderCell({ ...props, ...cellProps }) ?? null
  }
}
