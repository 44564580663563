import React from 'react'
import { HoverCard, type RenderCellProps } from '@bpinternal/ui-kit'
import { Code, Text } from '@radix-ui/themes'
import { JSONTree } from '../../../elementsv2'

type Props = RenderCellProps<any>
export const ObjectRenderer = ({ column, row }: Props) => {
  const key = column.key
  const cell = row[key]
  return (
    <HoverCard
      minWidth={'200px'}
      maxWidth={'600px'}
      trigger={
        <Text size={'1'} color="gray" truncate className="w-72">
          <Code variant="ghost">{JSON.stringify(cell.value)}</Code>
        </Text>
      }
    >
      <JSONTree data={cell.value} />
    </HoverCard>
  )
}
