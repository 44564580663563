import Ansi from 'ansi-to-react'
import cx from 'classnames'
import { subDays } from 'date-fns'
import { DateTime } from 'luxon'
import { useEffect, useRef, useState } from 'react'
import { HiChevronDown, HiChevronUp, HiOutlineArrowPath, HiOutlineCog6Tooth } from 'react-icons/hi2'
import { DateInput } from '../DateInput'
import { DocumentationLink } from '../DocumentationLink'
import { Log } from './types'
import { EmptyState, Popover, Spinner } from '~/elementsv2'
import { PageActionPortal } from '~/componentsV2'
import { Flex, ScrollArea, Switch, Text, Tooltip } from '@radix-ui/themes'
import { cn } from '~/utils'
import Terminal from '~/assets/emptyStates/terminal.svg?react'
import { InView } from 'react-intersection-observer'
import { Button, IconButton } from '@bpinternal/ui-kit'

const DYNAMODB_LOGS_EXPIRY_DAYS = 30
const fromDate = subDays(new Date(), DYNAMODB_LOGS_EXPIRY_DAYS)

export function LogsPanel({
  logs,
  onRefresh,
  onLoadPrevious,
  isFetching,
  isFetchingPreviousPage,
  startDate,
  onStartDateChanged,
  endDate,
  onEndDateChanged,
  hasPreviousPage,
}: {
  logs: Log[]
  onRefresh: () => void
  onLoadPrevious: () => void
  isFetching: boolean
  isFetchingPreviousPage: boolean
  startDate: Date
  onStartDateChanged: (date: Date) => void
  endDate: Date
  onEndDateChanged: (date: Date) => void
  hasPreviousPage: boolean
}): JSX.Element {
  const rootRef = useRef<null | HTMLDivElement>(null)
  const topRef = useRef<null | HTMLDivElement>(null)
  const bottomRef = useRef<null | HTMLDivElement>(null)

  const [isAtTop, setIsAtTop] = useState(false)
  const [isAtBottom, setIsAtBottom] = useState(false)

  const [showTimestamp, setShowTimestamp] = useState(true)
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true)

  const [validDates, setValidDates] = useState(true)

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const scrollToTop = () => {
    topRef.current?.scrollIntoView({ behavior: 'smooth' })
  }
  // refresh when r key is pressed
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'r' && !e.ctrlKey && !e.metaKey && !e.altKey) {
        onRefresh()
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [onRefresh])

  useEffect(() => {
    if (shouldScrollToBottom && !isFetching) {
      scrollToBottom()
      setShouldScrollToBottom(false)
    }
  }, [shouldScrollToBottom, isFetching])

  return (
    <div
      className={cx(
        'absolute inset-0 isolate flex flex-col gap-px  overflow-hidden rounded-md border border-gray-6 bg-gray-1 text-gray-12'
      )}
    >
      <PageActionPortal>
        <Flex align="center" gap={'4'}>
          <Flex gap={'2'} align={'center'}>
            <Text size={'2'}>From</Text>
            <DateInput
              value={startDate}
              onChange={(d) => {
                setShouldScrollToBottom(true)
                onStartDateChanged(d)
              }}
              onValidChanged={setValidDates}
              fromDate={fromDate}
            />
          </Flex>
          <Flex gap={'2'} align={'center'}>
            <Text size={'2'}>To</Text>
            <DateInput
              value={endDate}
              onChange={(d) => {
                setShouldScrollToBottom(true)
                onEndDateChanged(d)
              }}
              onValidChanged={setValidDates}
              fromDate={fromDate}
            />
          </Flex>
        </Flex>
        <Tooltip className="flex items-center" content={'Refresh'}>
          <IconButton
            variant="minimal"
            color="gray"
            onClick={() => onRefresh()}
            disabled={!validDates}
            icon={HiOutlineArrowPath}
            className={cn({ '[&>*]:animate-spin': isFetching })}
          />
        </Tooltip>
        <Popover trigger={<IconButton variant="minimal" color="gray" icon={HiOutlineCog6Tooth} />}>
          <Flex align={'center'} gap={'2'}>
            <Text size={'2'}>Show Timestamps</Text>
            <Switch checked={showTimestamp} onCheckedChange={setShowTimestamp} />
          </Flex>
        </Popover>
      </PageActionPortal>

      {logs.length === 0 && !isFetching ? (
        <EmptyState
          title="No logs found."
          description="No logs were found for the selected period. Please try another date range."
          icon={Terminal}
          iconSize={10}
          className="mt-12"
          primaryAction={<DocumentationLink page="/cloud/admin-dashboard/logs/" children="Learn more" />}
        />
      ) : (
        <ScrollArea ref={rootRef}>
          <InView onChange={(inView) => setIsAtTop(inView)}>
            <div ref={topRef} />
          </InView>
          <div
            className={cx('flex h-16 items-center justify-center', {
              invisible: isFetching && !isFetchingPreviousPage,
            })}
          >
            {isFetchingPreviousPage ? (
              <Spinner size="6" />
            ) : hasPreviousPage ? (
              <Button onClick={() => onLoadPrevious()}>Load more</Button>
            ) : (
              <Button className="text-gray-11" disabled={true}>
                No more logs for selected period
              </Button>
            )}
          </div>

          <div className="overflow-hidden bg-inherit px-0">
            <div className={cx('relative text-xs [&_*]:font-mono')}>
              {logs.map(({ timestamp, level, message }, index) => (
                <LogRow
                  key={index}
                  index={index}
                  date={new Date(timestamp)}
                  level={level}
                  message={message}
                  showTimestamp={showTimestamp}
                />
              ))}
            </div>
            <InView onChange={(inView) => setIsAtBottom(inView)}>
              <div ref={bottomRef} />
            </InView>
          </div>
          <IconButton
            variant="soft"
            color="gray"
            size={'2'}
            className={cn('absolute left-0 right-0 top-4 mx-auto rounded-full', { hidden: isAtTop })}
            icon={HiChevronUp}
            onClick={scrollToTop}
          />
          <IconButton
            variant="soft"
            color="gray"
            size={'2'}
            className={cn('absolute bottom-4 left-0 right-0 mx-auto rounded-full', { hidden: isAtBottom })}
            icon={HiChevronDown}
            onClick={scrollToBottom}
          />
        </ScrollArea>
      )}
    </div>
  )
}

function LogRow({
  date,
  level,
  message,
  showTimestamp,
}: {
  index: number
  date: Date
  message: string
  level: string
  showTimestamp: boolean
}): JSX.Element {
  const loglevel = level.toLowerCase()

  const datetime = DateTime.fromJSDate(date)
  const timestamp = datetime.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)

  return (
    <>
      <Flex
        gap={'2'}
        px={'3'}
        className={cx({
          'text-gray-11': loglevel === 'debug',
          '!bg-red-3 text-red-12': loglevel === 'error',
          '!bg-amber-3 text-amber-12': loglevel === 'warn',
        })}
      >
        {showTimestamp && <div className="leading-5 text-gray-10">{timestamp}</div>}
        <div className="flex-1 whitespace-pre-wrap leading-5">
          <Ansi className="break-all leading-5 [&>span]:inline-block [&>span]:leading-5">{formatMessage(message)}</Ansi>
        </div>
      </Flex>
    </>
  )
}

function formatMessage(message: string) {
  try {
    const json = JSON.parse(message)
    return JSON.stringify(json, null, 2)
  } catch {
    return message
  }
}
